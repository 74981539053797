import type { LoginMutationVariables } from '@modules/types/graphql';

export type LoginFormValues = {
    email: string;
    password: string;
};

class LoginRequest {
    email: LoginMutationVariables['email'];
    password: LoginMutationVariables['password'];

    constructor(values: LoginFormValues) {
        this.email = values.email;
        this.password = values.password;
    }
}

export { LoginRequest };
