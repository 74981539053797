import { breakpoints } from '@avangard/ui/utils';
import { Greeting } from '@modules/auth/organisms';
import React from 'react';
import styled from 'styled-components';

type GreetingLayoutProps = {
    children?: React.ReactElement;
};

const Root = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    background-image: url('/images/main-background.jpg');
    background-size: cover;
    min-height: 100%;

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);

        ${breakpoints.down('sm')} {
            content: unset;
        }
    }

    & > * {
        z-index: 1;
    }
`;

const GreetingLayout = (props: GreetingLayoutProps): React.ReactElement => {
    const { children } = props;

    return (
        <Root>
            <Wrapper>
                <Greeting>{children}</Greeting>
            </Wrapper>
        </Root>
    );
};

export { GreetingLayout };
