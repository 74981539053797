import { gql } from '@apollo/client';

export const FileFragment = gql`
    fragment File on File {
        id
        name
        mime
        size
        links {
            origin
            thumbnail
        }
    }
`;
