import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import { Maybe } from '@modules/types/graphql';

import type { Region } from '@modules/types/graphql';

export type Entity = Maybe<Region>;

@DecribeEntity('RegionEntity')
class RegionEntity extends BaseEntity {
    id: string;
    name: string;

    constructor(region: Entity) {
        super(region);

        this.id = region?.id ?? '';
        this.name = region?.name ?? '-';
    }
}

export { RegionEntity };
