import { gql } from '@apollo/client';

import { AuthTokensFragment } from '@modules/auth/graphql';

export const ResetPasswordMutation = gql`
    mutation ResetPassword($token: String!, $password: String!) {
        resetPassword(token: $token, password: $password) {
            ...AuthTokens
        }
    }
    ${AuthTokensFragment}
`;
