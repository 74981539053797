import { gql } from '@apollo/client';

import { DictationQuestionFragment } from './dictation-question';

export const DictationFragment = gql`
    fragment Dictation on Dictation {
        id
        eventId
        questionsCount
        activeQuestion {
            ...DictationQuestion
        }
        activeQuestionIndex
        startedAt
        finished
        finishedAt
    }
    ${DictationQuestionFragment}
`;
