import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';
import { UserEntity } from '@modules/users/entities';

import type { DictationUser } from '@modules/types/graphql';

export type Entity = Partial<DictationUser> | null;

@DecribeEntity('DictationUserEntity')
class DictationUserEntity extends BaseEntity {
    dictationId: string;
    eventId: string;
    eventName: string;
    finishedAt: string;
    resultScore: number | null;
    startedAt: string;

    @Relation(() => UserEntity)
    user: UserEntity;

    constructor(dictationUser: Entity) {
        super(dictationUser);

        this.dictationId = dictationUser?.dictationId ?? '';
        this.eventId = dictationUser?.eventId ?? '';
        this.eventName = dictationUser?.eventName ?? '';
        this.finishedAt = dictationUser?.finishedAt ?? '';
        this.resultScore = dictationUser?.resultScore ?? null;
        this.startedAt = dictationUser?.startedAt ?? '';
    }

    getFormattedScore() {
        if (typeof this.resultScore !== 'number') {
            return '-';
        }

        return this.resultScore;
    }
}

export { DictationUserEntity };
