import type { UserUpdate } from '@modules/types/graphql';
import type { RegionEntity } from '@modules/shared/entities';
import type { LocationEntity } from '@modules/shared/entities';

export type UserUpdateFormValues = {
    email?: string;
    name: string;
    region: RegionEntity | null;
    schoolName: string;
    phone: string;
    location: LocationEntity | null;
    privacyPolicy?: false;
    personalData?: false;
    personalDataAvangard?: false;
};

class UserUpdateRequest {
    doc: UserUpdate;

    constructor(values: UserUpdateFormValues) {
        this.doc = {
            name: values.name ?? undefined,
            schoolName: values.schoolName ?? undefined,
            phone: values.phone ?? undefined,
            locationId: values?.location?.id ?? undefined,
            greetingPassed: true,
        };
    }
}

export { UserUpdateRequest };
