import { gql } from '@apollo/client';

import { DictationQuestionFragment } from '@modules/events/dictation/graphql';

export const GetActiveDictationQuestionQuery = gql`
    query GetActiveDictationQuestion($dictationId: ID!) {
        getActiveDictationQuestion(dictationId: $dictationId) {
            questionIndex
            question {
                ...DictationQuestion
            }
        }
    }
    ${DictationQuestionFragment}
`;
