import React from 'react';
import { Dialog } from '@avangard/ui/core';

import { HeaderButtonBack } from '@modules/layout/atoms';

import type { DialogProps } from '@avangard/ui/core';

export type DialogSureBackProps = Pick<DialogProps, 'disabled' | 'onAccept' | 'onCancel'>;

const DialogSureBack = (props: DialogSureBackProps): React.ReactElement => {
    return (
        <Dialog
            title='Измененные данные не сохранены'
            content='Если вы выйдите, все изменения будут утеряны. Вы уверены, что хотите выйти не сохранив изменения?'
            cancelText='Выйти'
            acceptText='Сохранить'
            {...props}
        >
            {dialogProps => {
                const { handleOpen } = dialogProps;

                return <HeaderButtonBack onClick={handleOpen} />;
            }}
        </Dialog>
    );
};

export { DialogSureBack };
