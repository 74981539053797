import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useNavigate } from '@reach/router';
import { useMutation } from '@apollo/client';
import { Checkbox, FormControlLabel, Link, withStyles } from '@material-ui/core';
import { breakpoints } from '@avangard/ui/utils';
import { Button, TextField, Autocomplete } from '@avangard/ui/core';

import { routes } from '@config/routes';
import { useLazyLocationsByRegion, useRegions } from '@modules/shared/hooks';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { GetCurrentUserQuery, UpdateCurrentUserMutation } from '@modules/auth/graphql';
import { UserUpdateFormValues, UserUpdateRequest } from '@modules/auth/requests';

import type { LocationEntity, RegionEntity } from '@modules/shared/entities';
import type {
    UpdateCurrentUserMutationType,
    UpdateCurrentUserMutationVariables,
} from '@modules/types/graphql';

const StyledTextField = withStyles({
    root: {
        marginBottom: 24,
    },
})(TextField);

const StyledFormControlLabel = withStyles({
    root: {
        margin: '0 0 0 -11px',
    },
})(FormControlLabel);

const StyledForm = styled(Form)`
    width: 100%;
`;

const StyledFormColumnList = styled(FormStyled.FormColumnList)`
    > * {
        &:not(:last-of-type) {
            ${breakpoints.down('md')} {
                margin-bottom: 0;
            }

            ${breakpoints.down('xs')} {
                padding-right: 0;
            }
        }
    }
`;

const StyledFormRowWithMargin = styled(FormStyled.FormRow)`
    margin-top: 24px;
    justify-content: flex-end;
`;

const StyledFormColumn = styled(FormStyled.FormColumn)`
    max-width: 50%;

    ${breakpoints.down('xs')} {
        max-width: 100%;

        > * {
            margin-bottom: 24px;
        }
    }
`;

const StyledFormColumnFullWidth = styled(FormStyled.FormColumn)`
    max-width: 100% !important;
`;

const GreetingForm = (): React.ReactElement => {
    const navigate = useNavigate();

    const initialValues: UserUpdateFormValues = {
        name: '',
        schoolName: '',
        region: null,
        phone: '',
        location: null,
        privacyPolicy: false,
        personalData: false,
        personalDataAvangard: false,
    };

    const [updateCurrentUser] =
        useMutation<UpdateCurrentUserMutationType, UpdateCurrentUserMutationVariables>(
            UpdateCurrentUserMutation,
        );

    const { regions, loading: regionsLoading } = useRegions();

    const [
        getLocationsByRegion,
        { locationsByRegion, count: locationsByRegionCount, loading: locationsByRegionLoading },
    ] = useLazyLocationsByRegion();

    return (
        <ExtendedFormik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                name: yup.string().trim().required('Введите имя пользователя'),
                phone: yup.string().trim().required('Введите телефон'),
                region: yup.mixed().required('Выберите регион'),
                location: yup.mixed().required('Выберите населенный пункт'),
                privacyPolicy: yup.boolean().required('Необходимо согласиться с политикой сервиса'),
                personalData: yup.boolean().required('Необходимо согласиться с политикой сервиса'),
                personalDataAvangard: yup
                    .boolean()
                    .required('Необходимо согласиться с политикой сервиса'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const variables = new UserUpdateRequest(values);

                    if (
                        !values.privacyPolicy ||
                        !values.personalData ||
                        !values.personalDataAvangard
                    ) {
                        throw new Error('Необходимо согласиться с политикой сервиса');
                    }

                    await updateCurrentUser({
                        variables,
                        refetchQueries: [{ query: GetCurrentUserQuery }],
                        awaitRefetchQueries: true,
                    });

                    await navigate(routes.index.path);
                } catch (e) {
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, setFieldValue, handleChange, handleBlur, isSubmitting } =
                    formikProps;

                React.useEffect(() => {
                    if (!values.region) {
                        return;
                    }

                    values.location = null;

                    getLocationsByRegion({
                        variables: {
                            regionId: values.region?.id,
                        },
                    });
                }, [values.region]);

                const handleChangeAutocomplete =
                    (field: string) => (_: React.ChangeEvent<unknown>, option: any | null) =>
                        setFieldValue(field, option);

                return (
                    <StyledForm>
                        <StyledFormColumnList>
                            <StyledFormColumn>
                                <StyledTextField
                                    required
                                    fullWidth
                                    id='name'
                                    name='name'
                                    label='ФИО'
                                    placeholder='Введите ФИО'
                                    value={values.name}
                                    error={!!errors.name}
                                    helperText={errors.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <StyledTextField
                                    required
                                    fullWidth
                                    id='phone'
                                    name='phone'
                                    label='Мобильный телефон'
                                    placeholder='+7/+380'
                                    value={values.phone}
                                    error={!!errors.phone}
                                    helperText={errors.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <StyledTextField
                                    fullWidth
                                    id='schoolName'
                                    name='schoolName'
                                    label='Образовательная организация'
                                    placeholder='Введите наименование'
                                    value={values.schoolName}
                                    error={!!errors.schoolName}
                                    helperText={errors.schoolName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </StyledFormColumn>

                            <StyledFormColumn>
                                <Autocomplete<RegionEntity | null, false, false, false>
                                    required
                                    fullWidth
                                    id='region'
                                    label='Регион'
                                    placeholder='Выберите регион'
                                    options={regions}
                                    value={values.region}
                                    error={!!errors.region}
                                    helperText={errors.region}
                                    disabled={regionsLoading}
                                    getOptionLabel={option => option?.name ?? '-'}
                                    onChange={handleChangeAutocomplete('region')}
                                />

                                <Autocomplete<LocationEntity | null, false, false, false>
                                    required
                                    fullWidth
                                    id='location'
                                    label='Населенный пункт'
                                    placeholder='Выберите населенный пункт'
                                    options={locationsByRegion}
                                    value={values.location}
                                    error={!!errors.location}
                                    helperText={errors.location}
                                    disabled={
                                        locationsByRegionLoading || locationsByRegionCount === 0
                                    }
                                    getOptionLabel={option => option?.name ?? '-'}
                                    onChange={handleChangeAutocomplete('location')}
                                />
                            </StyledFormColumn>
                        </StyledFormColumnList>

                        <StyledFormColumnFullWidth>
                            <StyledFormControlLabel
                                id='privacyPolicy'
                                name='privacyPolicy'
                                label={
                                    <>
                                        Я подтверждаю, что{' '}
                                        <Link href='/privacy_policy_diktant.pdf' target='_blank'>
                                            Политика конфиденциальности
                                        </Link>{' '}
                                        мною прочитана и я принимаю её условия
                                    </>
                                }
                                control={
                                    <Checkbox
                                        checked={values.privacyPolicy}
                                        onChange={handleChange}
                                    />
                                }
                            />
                        </StyledFormColumnFullWidth>

                        <StyledFormColumnFullWidth>
                            <StyledFormControlLabel
                                id='personalData'
                                name='personalData'
                                label={
                                    <>
                                        Я даю согласие на обработку моих персональных данных,
                                        указанных при регистрации (в том числе дополнительно
                                        направленных), Российскому обществу «Знание», на условиях,
                                        определённых Политикой конфиденциальности с целью
                                        обеспечения принятия моего участия в Диктанте и получения
                                        сведений о его проведении и результатах.
                                    </>
                                }
                                control={
                                    <Checkbox
                                        checked={values.personalData}
                                        onChange={handleChange}
                                    />
                                }
                            />
                        </StyledFormColumnFullWidth>

                        <StyledFormColumnFullWidth>
                            <StyledFormControlLabel
                                id='personalDataAvangard'
                                name='personalDataAvangard'
                                label={
                                    <>
                                        Выражаю согласие на передачу моих персональных данных,
                                        указанных мною при регистрации, организатору Диктанта АНО
                                        «Авангард» (ОГРН: 1205000035250, Юридический адрес: 143070,
                                        Московская область, город Одинцово, тер Парк Патриот, стр.
                                        9, офис 1) в целях принятия участия в Диктанте.
                                    </>
                                }
                                control={
                                    <Checkbox
                                        checked={values.personalDataAvangard}
                                        onChange={handleChange}
                                    />
                                }
                            />
                        </StyledFormColumnFullWidth>

                        <StyledFormRowWithMargin>
                            <Button type='submit' loading={isSubmitting}>
                                Сохранить
                            </Button>
                        </StyledFormRowWithMargin>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { GreetingForm };
