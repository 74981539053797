import { gql } from '@apollo/client';

export const DictationUserFragment = gql`
    fragment DictationUser on DictationUser {
        user {
            id
            name
            email
            role
            locationId
            location {
                id
                regionId
                name
                region {
                    id
                    name
                }
            }
            phone
            schoolName
            greetingPassed
        }
        dictationId
        eventId
        eventName
        startedAt
        finishedAt
        resultScore
    }
`;
