import React from 'react';
import styled from 'styled-components';
import { black, white } from '@avangard/ui/colors';

type LayoutCardProps = {
    label?: string;
    children: React.ReactNode;
};

const Root = styled.div`
    background: ${white[100]};
    border: 1px solid ${white[70]};
    border-radius: 4px;
    padding: 16px;
`;

const Label = styled.span`
    color: ${black[80]};
    display: block;
    font-weight: bold;
    font-size: 1.6rem;
    width: 100%;
    margin-bottom: 12px;
`;

const LayoutCard = (props: LayoutCardProps): React.ReactElement => {
    const { label, children } = props;

    return (
        <Root>
            {label && <Label>{label}</Label>}

            {children}
        </Root>
    );
};

export { LayoutCard };
