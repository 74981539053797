import styled, { css } from 'styled-components';
import { breakpoints } from '@avangard/ui/utils';

export const PageStyled = {
    Root: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
    `,

    Main: styled.div`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    `,

    Actions: styled.div`
        flex: 0 0 auto;
        margin-left: auto;
        width: 190px;
        text-align: right;

        > * {
            &:not(:last-of-type) {
                margin-right: 8px;
            }
        }

        ${breakpoints.down('sm')} {
            width: 100%;
            text-align: left;
            order: -1;
            margin-bottom: 20px;
        }
    `,

    PageEdit: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: calc(100% + 64px);
        margin: 0 -32px;
        padding-top: 16px;

        ${breakpoints.down('lg')} {
            align-content: baseline;
        }

        ${breakpoints.down('xs')} {
            margin: 0 -16px;
            padding-top: 8px;
            width: calc(100% + 32px);
        }
    `,

    PageEditContainer: styled.div<{ fluid?: boolean }>`
        display: flex;
        flex-wrap: wrap;
        max-width: 83%;
        width: 100%;
        padding: 0 32px;

        ${p =>
            !!p.fluid &&
            css`
                max-width: 100%;
                height: 100%;
            `}

        ${breakpoints.down('md')} {
            max-width: 100%;
            padding: 0 16px;
        }
    `,
};
