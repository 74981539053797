import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Tab } from '@avangard/ui/core';

import { blue } from '@modules/layout/styled';

import type { TabProps } from '@avangard/ui/core';

const useTabStyles = makeStyles({
    root: {
        minWidth: 0,
        minHeight: 40,
        padding: 0,
        marginRight: 32,
        fontSize: '1.4rem',
        opacity: 1,

        '&:hover': {
            color: blue[100],
            backgroundColor: 'transparent',
        },
    },

    textColorInherit: {
        '&$selected': {
            color: blue[100],
            backgroundColor: 'transparent',
        },
    },

    wrapper: {
        textAlign: 'center',
    },

    selected: {},
});

const TabNavigation = (props: TabProps): React.ReactElement => {
    const tabClasses = useTabStyles();

    return <Tab classes={tabClasses} {...props} />;
};

export { TabNavigation };
