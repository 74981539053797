import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { DictationQuestionEntity } from '@modules/events/dictation/entities';

import type { Dictation } from '@modules/types/graphql';

export type Entity = Partial<Dictation> | null;

@DecribeEntity('DictationEntity')
class DictationEntity extends BaseEntity {
    id: string;
    eventId: string;
    questionsCount: number;
    activeQuestionIndex: number;
    startedAt: string;
    finished: boolean;
    finishedAt: string;

    @Relation(() => DictationQuestionEntity)
    activeQuestion: DictationQuestionEntity;

    constructor(dictationEntity: Entity) {
        super(dictationEntity);

        this.id = dictationEntity?.id ?? '';
        this.eventId = dictationEntity?.eventId ?? '';
        this.questionsCount = dictationEntity?.questionsCount ?? 0;
        this.activeQuestionIndex = dictationEntity?.activeQuestionIndex ?? 0;
        this.startedAt = dictationEntity?.startedAt ?? '';
        this.finished = dictationEntity?.finished ?? false;
        this.finishedAt = dictationEntity?.finishedAt ?? '';
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }

    isFinished() {
        const finished = this.finished === true;

        return finished;
    }
}

export { DictationEntity };
