import React from 'react';
import { makeStyles, IconButton, Popover } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { blue } from '@modules/layout/styled';

type InjectableChildrenProps = {
    onClick: (fn?: () => Promise<void> | void) => (event: React.ChangeEvent<any>) => Promise<void>;
    onClose: (event: React.ChangeEvent<any>) => void;
};

type ListItemActionsProps = {
    children: (props: InjectableChildrenProps) => React.ReactNode;
};

const useIconButtonStyles = makeStyles({
    root: {
        padding: 0,

        '&:hover': {
            color: blue[100],
            backgroundColor: 'transparent',
        },
    },
});

const usePopoverStyles = makeStyles({
    paper: {
        '& > li': {
            padding: '8px 12px',

            '& span': {
                display: 'flex',
                marginRight: 12,
            },
        },
    },
});

const moreVertIcon = <MoreVert style={{ fontSize: 16 }} />;

const DotActions = (props: ListItemActionsProps): React.ReactElement => {
    const { children } = props;

    const iconButtonClasses = useIconButtonStyles();
    const popoverClasses = usePopoverStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleTogglePopover = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();

        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClosePopover = (event: React.ChangeEvent<any>): void => {
        event.stopPropagation();

        setAnchorEl(null);
    };

    const handleClickItem =
        (fn?: () => Promise<void> | void) =>
        async (event: React.ChangeEvent<any>): Promise<void> => {
            event.stopPropagation();

            if (typeof fn === 'function') {
                await fn();
            }

            handleClosePopover(event);
        };

    React.useEffect(() => {
        return () => setAnchorEl(null);
    }, []);

    return (
        <>
            <IconButton disableRipple classes={iconButtonClasses} onClick={handleTogglePopover}>
                {moreVertIcon}
            </IconButton>

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: -4,
                    horizontal: 48,
                }}
                classes={popoverClasses}
                onClose={handleClosePopover}
            >
                {children({ onClick: handleClickItem, onClose: handleClosePopover })}
            </Popover>
        </>
    );
};

export { DotActions };
