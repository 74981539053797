import { EntityList } from '@lib/entity';
import { DictationUserEntity } from './dictation-user-entity';

import type { Entity } from './dictation-user-entity';

class DictationUserEntityList extends EntityList<DictationUserEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(DictationUserEntity, items, count);
    }
}

export { DictationUserEntityList };
