import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { RegionEntity } from '@modules/shared/entities/region-entity';

import type { Maybe, Location } from '@modules/types/graphql';

export type Entity = Maybe<Location>;

@DecribeEntity('LocationEntity')
class LocationEntity extends BaseEntity {
    id: string;
    regionId: string;
    name: string;

    @Relation(() => RegionEntity)
    region: RegionEntity;

    constructor(location: Entity) {
        super(location);

        this.id = location?.id ?? '';
        this.regionId = location?.regionId ?? '';
        this.name = location?.name ?? '';
    }
}

export { LocationEntity };
