import { EntityList } from '@lib/entity';
import { DictationResultsByUserEntity } from './dictation-results-by-user-entity';

import type { Entity } from './dictation-results-by-user-entity';

class DictationResultsByUserEntityList extends EntityList<DictationResultsByUserEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(DictationResultsByUserEntity, items, count);
    }
}

export { DictationResultsByUserEntityList };
