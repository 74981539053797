import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { LocationEntity } from '@modules/shared/entities';
import { roleTranslates } from '@modules/users/constants';
import { UserRole } from '@modules/types/graphql';

import type { Maybe, User } from '@modules/types/graphql';

export type Entity = Maybe<Partial<User>>;

@DecribeEntity('UserEntity')
class UserEntity extends BaseEntity {
    id: string;
    name: string;
    email: string;
    role: UserRole | null;
    locationId: string;
    phone: string;
    schoolName: string;
    greetingPassed: boolean;

    @Relation(() => LocationEntity)
    location: LocationEntity;

    constructor(user: Entity) {
        super(user);

        this.id = user?.id ?? '';
        this.name = user?.name ?? '';
        this.email = user?.email ?? '';
        this.role = user?.role ?? null;
        this.locationId = user?.locationId ?? '';
        this.phone = user?.phone ?? '';
        this.schoolName = user?.schoolName ?? '';
        this.greetingPassed = user?.greetingPassed ?? false;
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }

    getRole() {
        if (!this.role) {
            return '-';
        }

        return roleTranslates[this.role];
    }
}

export { UserEntity };
