import { gql } from '@apollo/client';

import { UserFragment } from '@modules/users/graphql';

export const GetCurrentUserQuery = gql`
    query GetCurrentUser {
        getCurrentUser {
            ...User
        }
    }
    ${UserFragment}
`;
