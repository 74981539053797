export { DictationFragment, DictationAnswerFragment, DictationQuestionFragment } from './fragments';
export {
    AnswerTheQuestionOfDictationMutation,
    PrevQuestionOfDictationMutation,
    NextQuestionOfDictationMutation,
    StartDictationByEventMutation,
    StopDictationByEventMutation,
} from './mutations';

export { GetActiveDictationByEventQuery, GetActiveDictationQuestionQuery } from './queries';

export { WatchActiveQuestionOfDictationSubscription } from './subscription';
