import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';
import {
    DictationQuestionEntity,
    DictationQuestionEntityList,
} from '@modules/events/dictation/entities';

import type { DictationResultByUser } from '@modules/types/graphql';

export type Entity = Partial<DictationResultByUser> | null;

@DecribeEntity('DictationResultByUserEntity')
class DictationResultByUserEntity extends BaseEntity {
    eventId: string;
    eventName: string;
    dictationId: string;
    resultScore: number;

    @RelationList(() => DictationQuestionEntityList)
    questions: DictationQuestionEntity[];

    constructor(dictationResultByUser: Entity) {
        super(dictationResultByUser);

        this.eventId = dictationResultByUser?.eventId ?? '';
        this.eventName = dictationResultByUser?.eventName ?? '';
        this.dictationId = dictationResultByUser?.dictationId ?? '';
        this.resultScore = dictationResultByUser?.resultScore ?? 0;
    }
}

export { DictationResultByUserEntity };
