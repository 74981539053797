import { EntityList } from '@lib/entity';
import { FileEntity } from './file-entity';

import type { Entity } from './file-entity';

class FileEntityList extends EntityList<FileEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(FileEntity, items, count);
    }
}

export { FileEntityList };
