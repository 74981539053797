import { gql } from '@apollo/client';

import { DictationFragment } from '@modules/events/dictation/graphql';

export const GetActiveDictationByEventQuery = gql`
    query GetActiveDictationByEvent($eventId: ID!) {
        getActiveDictationByEvent(eventId: $eventId) {
            ...Dictation
        }
    }
    ${DictationFragment}
`;
