import React from 'react';
import { useNavigate, Redirect } from '@reach/router';

import { TokenSession } from '@lib/token';
import { routes } from '@config/routes';
import { useCurrentUser } from '@modules/auth/hooks';

import type { Route } from '@config/routes';

const PrivateRoute = (props: Route): React.ReactElement | null => {
    const { redirectTo, roles, renderFor, Component, LayoutComponent, ...otherProps } = props;

    const navigate = useNavigate();

    const hasTokens = TokenSession.getCurrentSession().hasTokens();

    const {
        currentUser,
        loading: userLoading,
        error: userError,
    } = useCurrentUser({
        skip: !hasTokens,
    });

    React.useEffect(() => {
        if (redirectTo) {
            navigate(redirectTo);
        }
    }, []);

    if (userLoading) {
        return null;
    }

    if (userError || !currentUser.authenticated()) {
        return <Redirect noThrow to={routes.login.path} />;
    }

    if (!currentUser.hasAccess(roles)) {
        return <Redirect noThrow to={routes.index.path} />;
    }

    if (currentUser.role && renderFor?.hasOwnProperty(currentUser.role)) {
        const RoleComponent = renderFor[currentUser.role];

        return (
            <LayoutComponent pageTitle={otherProps.title} withBackIcon={otherProps.withBackIcon}>
                {RoleComponent ? <RoleComponent {...otherProps} /> : null}
            </LayoutComponent>
        );
    }

    return (
        <LayoutComponent pageTitle={otherProps.title} withBackIcon={otherProps.withBackIcon}>
            {Component && !redirectTo ? <Component {...otherProps} /> : null}
        </LayoutComponent>
    );
};

export { PrivateRoute };
