import { RestLink } from 'apollo-link-rest';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createClient } from 'graphql-ws';
import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    Operation,
    split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

import { GRAPHQL_URL, REST_URL, SOCKET_URL } from '@config/environment';
import { TokenSession } from '@lib/token';
import { SystemHelper } from '@helpers';
import { RefreshTokenMutation } from '@modules/auth/graphql';
import { toPromise } from './utils';

import type {
    ApolloClientOptions as BaseApolloClientOptions,
    NormalizedCacheObject,
} from '@apollo/client';
import type { SetTokenSessionPayload } from '@lib/token';

type ApolloClientOptions = Omit<BaseApolloClientOptions<NormalizedCacheObject>, 'cache'>;

const isDev = SystemHelper.isDev();

const noopPromise = new Promise<void>(resolve => resolve());

const getAuthorization = () => {
    const authorization = TokenSession.getCurrentSession().getAccessToken().getToken();

    if (!authorization) {
        return {};
    }

    return {
        authorization,
    };
};

const updateContextHttpLink = (prevContext: any) => {
    const headers = { ...prevContext.headers };

    const token = getAuthorization();

    return {
        ...prevContext,
        headers: {
            ...headers,
            authorization: token?.authorization ? `Bearer ${token.authorization}` : null,
        },
    };
};

const createApolloClient = (options: ApolloClientOptions = {}) => {
    const graphqlUri = GRAPHQL_URL;
    const socketUri = SOCKET_URL;
    const restUri = REST_URL;

    const httpLink = new HttpLink({ uri: graphqlUri });

    const wsClient = createClient({
        url: socketUri,
        connectionParams: () => getAuthorization(),
        retryAttempts: 5,
        shouldRetry: () => true,
    });

    const wsLink = new GraphQLWsLink(wsClient);

    const authHttpLink = setContext((_, prevContext) => updateContextHttpLink(prevContext));

    const tokenRefreshLink = new TokenRefreshLink<SetTokenSessionPayload>({
        accessTokenField: 'tokens',

        isTokenValidOrUndefined: () =>
            !TokenSession.getCurrentSession().getAccessToken().isExpired() ||
            !TokenSession.getCurrentSession().hasTokens(),

        fetchAccessToken: (): Promise<any> => {
            const refreshToken = TokenSession.getCurrentSession().getRefreshToken();

            if (!refreshToken.issetToken()) {
                return noopPromise;
            }

            const promise = toPromise(
                HttpLink.execute(httpLink, {
                    query: RefreshTokenMutation,
                    variables: {
                        refreshToken: refreshToken.getToken(),
                    },
                }),
            );

            return promise;
        },

        handleFetch: (payload: SetTokenSessionPayload, operation: Operation) => {
            TokenSession.setCurrentSession(payload);
            wsLink.client.terminate();

            operation.setContext((prevContext: any) => updateContextHttpLink(prevContext));
        },

        handleResponse: () => (response: any) => {
            const responseData = response?.data?.refreshToken;

            if (responseData?.accessToken && responseData?.refreshToken) {
                return {
                    tokens: {
                        accessToken: responseData.accessToken,
                        refreshToken: responseData.refreshToken,
                    },
                };
            }

            return {
                tokens: {},
            };
        },

        handleError: error => {
            TokenSession.destroyCurrentSession();
        },
    });

    const restLink = new RestLink({
        uri: restUri,
        bodySerializers: {
            fileEncode: (data: any, headers: Headers) => {
                const formData = new FormData();

                formData.append('file', data, data.name);

                return { body: formData, headers };
            },
        },
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink,
    );

    const link = ApolloLink.from([authHttpLink, restLink, tokenRefreshLink, splitLink]);

    const apolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache(),
        connectToDevTools: isDev,
        ...options,
    });

    return apolloClient;
};

export { createApolloClient };
