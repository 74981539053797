import type { ResetPasswordMutationVariables } from '@modules/types/graphql';

export type ResetPasswordFormValues = {
    token: string;
    password: string;
};

class ResetPasswordRequest {
    token: ResetPasswordMutationVariables['token'];
    password: ResetPasswordMutationVariables['password'];

    constructor(values: ResetPasswordFormValues) {
        this.token = values.token;
        this.password = values.password;
    }
}

export { ResetPasswordRequest };
