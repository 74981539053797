import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import { FileEntity, FileEntityList } from '@modules/shared/entities';
import {
    DictationAnswerEntity,
    DictationAnswerEntityList,
} from '@modules/events/dictation/entities';

import type { DictationQuestion } from '@modules/types/graphql';

export type Entity = Partial<DictationQuestion> | null;

@DecribeEntity('DictationQuestionEntity')
class DictationQuestionEntity extends BaseEntity {
    id: string;
    dictationId: string;
    photoIds: string[];
    text: string;
    correctAnswersCount: number;

    @RelationList(() => FileEntityList)
    photos: FileEntity[];

    @RelationList(() => DictationAnswerEntityList)
    answers: DictationAnswerEntity[];

    constructor(dictationQuestion: Entity) {
        super(dictationQuestion);

        this.id = dictationQuestion?.id ?? '';
        this.dictationId = dictationQuestion?.dictationId ?? '';
        this.photoIds = dictationQuestion?.photoIds ?? [];
        this.text = dictationQuestion?.text ?? '';
        this.correctAnswersCount = dictationQuestion?.correctAnswersCount ?? 0;
    }

    hasMultipleCorrectAnswers() {
        const multipleCorrectAnswers = this.correctAnswersCount > 1;

        return multipleCorrectAnswers;
    }

    isCorrectAnswers() {
        if (!this.answers) return false;

        const correctAnswers = JSON.stringify(this.answers.filter(answer => answer.isCorrect));
        const selectedAnswers = JSON.stringify(this.answers.filter(answer => answer.selected));

        return correctAnswers === selectedAnswers;
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }
}

export { DictationQuestionEntity };
