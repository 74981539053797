import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { LocationEntityList } from '@modules/shared/entities';
import { GetLocationsByRegionQuery } from '@modules/shared/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetLocationsByRegionQueryType,
    GetLocationsByRegionQueryVariables,
} from '@modules/types/graphql';

const useLocationsByRegion = (
    options?: QueryHookOptions<GetLocationsByRegionQueryType, GetLocationsByRegionQueryVariables>,
) => {
    const queryResult = useQuery<GetLocationsByRegionQueryType, GetLocationsByRegionQueryVariables>(
        GetLocationsByRegionQuery,
        options,
    );

    const { entities: locationsByRegion, count } = useEntity(
        () => new LocationEntityList(queryResult.data?.getLocationsByRegion ?? null),
        [queryResult.data],
    );

    const result = {
        locationsByRegion,
        count,
        ...queryResult,
    };

    return result;
};

export { useLocationsByRegion };
