import React from 'react';
import styled from 'styled-components';

import { AvangardLogo, StockLogo } from '@modules/layout/icons';

type LogosProps = {
    className?: string;
};

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > *:not(:last-child) {
        margin-right: 30px;
    }
`;

const Logos = (props: LogosProps): React.ReactElement => {
    const { className } = props;

    return (
        <Root className={className}>
            <StockLogo />
            <AvangardLogo />
        </Root>
    );
};

export { Logos };
