import { gql } from '@apollo/client';

import { FileFragment } from '@modules/shared/graphql/fragments';

export const GetFileInfoQuery = gql`
    query GetFileInfo($fileId: ID!) {
        getFileInfo(fileId: $fileId) {
            ...File
        }
    }
    ${FileFragment}
`;
