import React from 'react';
import styled, { css } from 'styled-components';
import { white, black, gray } from '@avangard/ui/colors';

import { blue } from '@modules/layout/styled';

type DeskCardProps = {
    disabled?: boolean;
    partialDisabled?: boolean;
    title?: string;
    children?: React.ReactNode;
    BadgetComponent?: React.ReactNode;
    ActionsComponent?: React.ReactNode;
    className?: string;
    onClick?: () => void;
};

const Root = styled.div<{ disabled?: boolean; partialDisabled?: boolean; clickable?: boolean }>`
    width: 100%;
    background-color: ${white[100]};
    border: 1px solid ${gray[30]};
    border-radius: 4px;
    transition: 0.2s;

    ${p =>
        !!p.clickable &&
        css`
            cursor: pointer;

            &:hover {
                border-color: ${blue[100]};
            }
        `}

    ${p =>
        !!p.partialDisabled &&
        css`
            background-color: ${white[50]};
        `}

  ${p =>
        !!p.disabled &&
        css`
            background-color: ${white[50]};
            pointer-events: none;
            cursor: default;
        `}
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 48px;
    padding: 8px 16px;
    border-bottom: 1px solid ${gray[30]};
`;

const Badget = styled.div`
    width: 100%;
    padding: 8px 0;
`;

const Title = styled.div<{ hasActions?: boolean }>`
    color: ${black[50]};
    font-size: 1.4rem;
    font-weight: 500;

    ${p =>
        !!p.hasActions &&
        css`
            padding-right: 22px;
        `}
`;

const Actions = styled.div`
    position: absolute;
    right: 12px;
    top: 16px;
    height: 16px;
    width: 16px;
`;

const Body = styled.div`
    width: 100%;
    height: 100%;
    padding: 12px 16px;
`;

const noop = () => ({});

const DeskCard = (props: DeskCardProps): React.ReactElement => {
    const {
        disabled,
        partialDisabled,
        title,
        children,
        BadgetComponent,
        ActionsComponent,
        onClick,
        ...otherProps
    } = props;

    const hasHeader = !!title || !!ActionsComponent;
    const clickable = !disabled && !!onClick;

    const handleClick = disabled || partialDisabled ? noop : onClick;

    return (
        <Root
            disabled={disabled}
            partialDisabled={partialDisabled}
            clickable={clickable}
            onClick={handleClick}
            {...otherProps}
        >
            {hasHeader ? (
                <Header>
                    {BadgetComponent ? <Badget>{BadgetComponent}</Badget> : null}
                    <Title hasActions={!!ActionsComponent}>{title}</Title>
                    {ActionsComponent ? <Actions>{ActionsComponent}</Actions> : null}
                </Header>
            ) : null}

            {children ? <Body>{children}</Body> : null}
        </Root>
    );
};

export { DeskCard };
