import { gql } from '@apollo/client';

export const DictationResultsByUserItemFragment = gql`
    fragment DictationResultsByUserItem on DictationResultsByUserItem {
        eventId
        eventName
        dictationId
        startedAt
        finishedAt
        resultScore
    }
`;
