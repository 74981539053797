import { useLazyQuery } from '@apollo/client';
import { useEntity } from '@lib/entity';

import { LocationEntity, LocationEntityList } from '@modules/shared/entities';
import { GetLocationsByRegionQuery } from '@modules/shared/graphql';

import type { QueryLazyOptions, LazyQueryResult, QueryHookOptions } from '@apollo/client';
import type {
    GetLocationsByRegionQueryType,
    GetLocationsByRegionQueryVariables,
} from '@modules/types/graphql';

type Tuple = [
    (options?: QueryLazyOptions<GetLocationsByRegionQueryVariables>) => void,
    LazyQueryResult<GetLocationsByRegionQueryType, GetLocationsByRegionQueryVariables> & {
        locationsByRegion: LocationEntity[];
        count: number;
    },
];

const useLazyLocationsByRegion = (
    options?: QueryHookOptions<GetLocationsByRegionQueryType, GetLocationsByRegionQueryVariables>,
): Tuple => {
    const [getLocationsByRegion, queryResult] = useLazyQuery<
        GetLocationsByRegionQueryType,
        GetLocationsByRegionQueryVariables
    >(GetLocationsByRegionQuery, options);

    const { entities: locationsByRegion, count } = useEntity(
        () => new LocationEntityList(queryResult.data?.getLocationsByRegion ?? null),
        [queryResult.data],
    );

    return [getLocationsByRegion, { locationsByRegion, count, ...queryResult }];
};

export { useLazyLocationsByRegion };
