import { gql } from '@apollo/client';

import { PagingFragment } from '@modules/shared/graphql';
import { DictationUserFragment } from '@modules/users/graphql/fragments';

export const GetDictationUsersCollectionQuery = gql`
    query GetDictationUsersCollection(
        $filter: DictationUsersFilter
        $order: DictationUsersOrder
        $page: PagingInput
    ) {
        getDictationUsersCollection(filter: $filter, order: $order, page: $page)
            @connection(key: "getDictationUsersCollection") {
            users {
                ...DictationUser
            }
            page {
                ...Paging
            }
        }
    }
    ${DictationUserFragment}
    ${PagingFragment}
`;
