export const tablePerPage = [
    {
        title: '10 / стр.',
        value: 10,
    },
    {
        title: '20 / стр.',
        value: 20,
    },
    {
        title: '50 / стр.',
        value: 50,
    },
    {
        title: '100 / стр.',
        value: 100,
    },
];
