import { gql } from '@apollo/client';

import { PagingFragment } from '@modules/shared/graphql';
import { DictationResultsByUserItemFragment } from '@modules/users/graphql/fragments';

export const GetDictationResultsCollectionByUserQuery = gql`
    query GetDictationResultsCollectionByUser(
        $filter: DictationResultsByUserFilter
        $order: DictationResultsByUserOrder
        $page: PagingInput
    ) {
        getDictationResultsCollectionByUser(filter: $filter, order: $order, page: $page)
            @connection(key: "getDictationResultsCollectionByUser") {
            results {
                ...DictationResultsByUserItem
            }
            page {
                ...Paging
            }
        }
    }
    ${DictationResultsByUserItemFragment}
    ${PagingFragment}
`;
