import { formatInTimeZone } from 'date-fns-tz';
import { ru } from 'date-fns/locale';

import { StringHelper } from '@helpers';
import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { DictationResultsByUserItem } from '@modules/types/graphql';

export type Entity = Partial<DictationResultsByUserItem> | null;

@DecribeEntity('DictationResultsByUserEntity')
class DictationResultsByUserEntity extends BaseEntity {
    eventId: string;
    eventName: string;
    dictationId: string;
    startedAt: string;
    finishedAt: string;
    resultScore: number;

    constructor(dictationResultsByUser: Entity) {
        super(dictationResultsByUser);

        this.eventId = dictationResultsByUser?.eventId ?? '';
        this.eventName = dictationResultsByUser?.eventName ?? '';
        this.dictationId = dictationResultsByUser?.dictationId ?? '';
        this.startedAt = dictationResultsByUser?.startedAt ?? '';
        this.finishedAt = dictationResultsByUser?.finishedAt ?? '';
        this.resultScore = dictationResultsByUser?.resultScore ?? 0;
    }

    getFormattedDate(dateString: string) {
        if (dateString === null) {
            return '';
        }

        try {
            const currentDate = new Date(dateString);

            const date = formatInTimeZone(currentDate, 'Europe/Moscow', 'dd MMMM yyyy', {
                locale: ru,
            });

            return StringHelper.capitalizeFirstLetter(date);
        } catch {
            return '';
        }
    }

    getFormattedTime(dateString: string) {
        if (dateString === null) {
            return '';
        }

        try {
            const currentDate = new Date(dateString);

            const date = formatInTimeZone(currentDate, 'Europe/Moscow', 'HH:mm', {
                locale: ru,
            });

            return StringHelper.capitalizeFirstLetter(date);
        } catch {
            return '';
        }
    }
}

export { DictationResultsByUserEntity };
