import { EntityList } from '@lib/entity';
import { DictationAnswerEntity } from './dictation-answer-entity';

import type { Entity } from './dictation-answer-entity';

class DictationAnswerEntityList extends EntityList<DictationAnswerEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(DictationAnswerEntity, items, count);
    }
}

export { DictationAnswerEntityList };
