const globalStyles = `
    body {
        font-family: 'Open Sans', -apple-system, 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji';
    }
    html, body { height: 100%; }
    
    
    ::selection {
      background-color: #0061BB;
    }
`;

export { globalStyles };
