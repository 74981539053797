import React from 'react';
import {
    RadioButtonChecked,
    RadioButtonUnchecked,
    CheckBox,
    CheckBoxOutlineBlank,
    IndeterminateCheckBox,
} from '@material-ui/icons';

import { black, white, gray } from '@avangard/ui/colors';

export const blue = {
    10: '#ebf3ff',
    20: '#bfddff',
    50: '#0073de',
    100: '#0061BB',
};

const radioCheckedIcon = <RadioButtonChecked style={{ fontSize: 20 }} />;
const radioIcon = <RadioButtonUnchecked style={{ fontSize: 20 }} />;
const checkedIcon = <CheckBox style={{ fontSize: 20 }} />;
const icon = <CheckBoxOutlineBlank style={{ fontSize: 20 }} />;
const indeterminateIcon = <IndeterminateCheckBox style={{ fontSize: 20 }} />;

const muiTheme = {
    typography: {
        fontFamily: [
            'Open Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
        ],
    },

    palette: {
        primary: {
            main: blue[100],
        },
        text: {
            primary: black[60],
        },
    },

    props: {
        MuiTextField: {
            variant: 'outlined',
            size: 'medium',
        },

        MuiRadio: {
            color: 'primary',
            checkedIcon: radioCheckedIcon,
            icon: radioIcon,
        },

        MuiCheckbox: {
            color: 'primary',
            checkedIcon,
            icon,
            indeterminateIcon,
        },

        MuiButton: {
            variant: 'contained',
            color: 'primary',
            size: 'medium',
        },

        MuiAutocomplete: {
            noOptionsText: 'Нет данных',
        },
    },

    overrides: {
        MuiPaginationItem: {
            root: {
                fontSize: '1.4rem',

                '&$page': {
                    transition: 'all .2s',

                    '&$selected': {
                        color: blue[100],
                        borderColor: blue[100],
                        backgroundColor: 'transparent',

                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    },

                    '&:hover': {
                        borderColor: blue[100],
                        backgroundColor: 'transparent',
                    },
                },
            },
            icon: {
                fontSize: '1.4rem',
            },
        },

        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: 'initial',
                },
                '&$disabled': {
                    color: 'initial',
                },
            },
            disabled: {},
            focused: {},
        },

        MuiFormControlLabel: {
            root: {
                marginLeft: -6,
            },
            label: {
                fontSize: '1.4rem',
            },
        },

        MuiFormHelperText: {
            root: {
                fontSize: '1.4rem',
                fontWeight: 300,
            },
            contained: {
                marginLeft: 0,
            },
        },

        MuiButton: {
            root: {
                fontSize: '1.4rem',
                fontWeight: 400,
                textTransform: 'none',
            },
            containedPrimary: {
                boxShadow: 'none',

                '&:hover': {
                    backgroundColor: blue[50],
                    boxShadow: 'none',
                },
            },
            outlinedPrimary: {
                color: black[60],
                borderColor: gray[40],
                transition: 'all .2s',

                '&:hover': {
                    color: blue[100],
                    backgroundColor: 'transparent',
                },
            },
        },

        MuiAutocomplete: {
            option: {
                fontSize: '1.4rem',
            },
            noOptions: {
                fontSize: '1.4rem',
            },
            inputRoot: {
                minHeight: 32,
                height: 'initial',

                '&[class*="MuiOutlinedInput-root"]': {
                    padding: 4,

                    '& $input': {
                        height: '100%',
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
            tag: {
                maxWidth: '100%',
                height: 'initial',
                margin: '2px 4px',
                padding: 0,
                color: black[60],
                fontSize: '1.4rem',
                lineHeight: '22px',
                backgroundColor: white[50],
                border: `1px solid ${white[70]}`,
                borderRadius: 4,

                '& > svg': {
                    width: 18,
                    height: 18,
                },

                '& > [class*="MuiChip-deleteIcon"]': {
                    position: 'relative',
                    top: 2,
                    right: 4,
                    margin: 0,
                },
            },
            endAdornment: {
                top: 'calc(50% - 12px)',
            },
            input: {},
        },

        MuiOutlinedInput: {
            root: {
                '&:hover': {
                    '& $notchedOutline': {
                        borderColor: blue[50],
                    },
                },

                '&$disabled': {
                    backgroundColor: white[70],

                    '&:hover': {
                        '& $notchedOutline': {
                            borderColor: 'rgba(0, 0, 0, 0.26)',
                        },
                    },

                    '& $input': {
                        cursor: 'not-allowed',
                    },
                },
            },
            input: {
                height: 'inherit',
                paddingTop: 0,
                paddingBottom: 0,
            },
            notchedOutline: {
                fontSize: '1.4rem',
            },
        },

        MuiInputLabel: {
            root: {
                color: black[60],
                fontSize: '1.4rem',
            },
            asterisk: {
                color: blue[100],
            },
        },

        MuiInputBase: {
            root: {
                '&$multiline': {
                    paddingTop: 12,
                    paddingBottom: 12,
                },
            },
            input: {
                fontSize: '1.4rem',
                lineHeight: '22px',

                '&::-webkit-input-placeholder': {
                    position: 'relative',
                    top: -1,
                    color: gray[70],
                    opacity: 1,
                },
            },
            multiline: {},
        },

        MuiMenu: {
            paper: {
                maxHeight: 300,
                marginTop: 8,
            },
        },

        MuiMenuItem: {
            root: {
                fontSize: '1.4rem',
            },
        },

        MuiTooltip: {
            tooltip: {
                minWidth: 30,
                minHeight: 32,
                margin: '12px 0',
                padding: '6px 8px',
                fontSize: '1.4rem',
                fontWeight: 400,
                textAlign: 'left',
                lineHeight: '22px',
                backgroundColor: black[70],
                boxShadow: `0 2px 8px rgba(0, 0, 0, 0.15)`,
            },
            arrow: {
                color: black[70],
            },
        },

        MuiPopover: {
            paper: {
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            },
        },
    },
};

export { muiTheme };
