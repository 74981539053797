import { Token } from './token';
import { Maybe } from '@modules/types/graphql';

export type SetTokenSessionPayload = {
    accessToken?: Maybe<string>;
    refreshToken?: Maybe<string>;
    rememberMe?: boolean;
};

class TokenSession {
    static getAccessTokenField() {
        return 'avDictationAccessToken';
    }

    static getRefreshTokenField() {
        return 'avDictationRefreshToken';
    }

    static getCurrentSession() {
        return new TokenSession();
    }

    static setCurrentSession(payload: SetTokenSessionPayload) {
        try {
            if (payload.accessToken) {
                Token.saveInStorage(
                    TokenSession.getAccessTokenField(),
                    payload.accessToken,
                    payload.rememberMe,
                );
            }

            if (payload.refreshToken) {
                Token.saveInStorage(
                    TokenSession.getRefreshTokenField(),
                    payload.refreshToken,
                    payload.rememberMe,
                );
            }
        } catch (e) {
            console.log(e);
        }
    }

    static async destroyCurrentSession() {
        try {
            Token.destroyInStorage(TokenSession.getAccessTokenField());
            Token.destroyInStorage(TokenSession.getRefreshTokenField());
        } catch (e) {
            console.log(e);
        }
    }

    hasTokens() {
        const isset = this.getAccessToken().issetToken() || this.getRefreshToken().issetToken();

        return isset;
    }

    getAccessToken(): Token {
        const accessToken = new Token(TokenSession.getAccessTokenField());

        return accessToken;
    }

    getRefreshToken(): Token {
        const refreshToken = new Token(TokenSession.getRefreshTokenField());

        return refreshToken;
    }
}

export { TokenSession };
