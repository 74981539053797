import { gql } from '@apollo/client';
import { DictationQuestionFragment } from '@modules/events/dictation/graphql';

export const GetDictationResultByUserQuery = gql`
    query GetDictationResultByUser($dictationId: ID!, $userId: ID!) {
        getDictationResultByUser(dictationId: $dictationId, userId: $userId) {
            eventId
            eventName
            dictationId
            resultScore
            questions {
                ...DictationQuestion
            }
        }
    }
    ${DictationQuestionFragment}
`;
