import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@avangard/ui/utils';

import { WelcomeLogo } from '@modules/layout/icons';
import { Logos } from '@modules/layout/atoms';

type AuthLayoutProps = {
    children?: React.ReactElement;
};

const Root = styled.main`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    ${breakpoints.down('xs')} {
        flex-direction: column;
        justify-content: initial;
    }
`;

const Logo = styled(Logos)`
    max-width: 280px;
    margin-bottom: 20px;

    ${breakpoints.down('md')} {
        max-width: 230px;
    }

    ${breakpoints.down('xs')} {
        display: none;
    }
`;

const Screen = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const WelcomeScreen = styled(Screen)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    min-width: 266px;
    width: 35%;
    background-image: url('/images/main-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    ${breakpoints.down('md')} {
        & > *:last-child {
            max-width: 160px;
        }
    }

    ${breakpoints.down('xs')} {
        width: 100%;
        height: 80px;
        background-size: 100% auto;
    }
`;

const SigninScreen = styled(Screen)`
    width: 100%;
    flex: 1 1 auto;

    ${breakpoints.down('xs')} {
        height: 100%;
        padding: 10px;
    }
`;

const AuthLayout = (props: AuthLayoutProps): React.ReactElement => {
    const { children } = props;

    return (
        <Root>
            <Wrapper>
                <WelcomeScreen>
                    <Logo />

                    <WelcomeLogo />
                </WelcomeScreen>

                <SigninScreen>{children}</SigninScreen>
            </Wrapper>
        </Root>
    );
};

export { AuthLayout };
