import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Time: any;
    _Any: any;
    _FieldSet: any;
};

export type ActiveDictationQuestion = {
    __typename?: 'ActiveDictationQuestion';
    question?: Maybe<DictationQuestion>;
    questionIndex?: Maybe<Scalars['Int']>;
};

export type AuthTokens = {
    __typename?: 'AuthTokens';
    accessToken?: Maybe<Scalars['String']>;
    refreshToken?: Maybe<Scalars['String']>;
};

export type Dictation = {
    __typename?: 'Dictation';
    /** Текущий активный вопрос диктанта */
    activeQuestion?: Maybe<DictationQuestion>;
    /** Индекс текущего вопроса. Отсчет с нуля */
    activeQuestionIndex?: Maybe<Scalars['Int']>;
    event?: Maybe<Event>;
    eventId?: Maybe<Scalars['ID']>;
    /** Завершен ли диктант */
    finished?: Maybe<Scalars['Boolean']>;
    /** Дата, когда диктант был завершен */
    finishedAt?: Maybe<Scalars['Time']>;
    id?: Maybe<Scalars['ID']>;
    /** Кол-во вопросов в диктанте */
    questionsCount?: Maybe<Scalars['Int']>;
    /** Дата начала диктанта */
    startedAt?: Maybe<Scalars['Time']>;
};

export type DictationAnswer = {
    __typename?: 'DictationAnswer';
    id?: Maybe<Scalars['ID']>;
    /** Верный ли ответ. Значение доступно только для администратора */
    isCorrect?: Maybe<Scalars['Boolean']>;
    /** Картинка в ответе, если есть */
    photo?: Maybe<File>;
    photoId?: Maybe<Scalars['ID']>;
    /** Выбран ли данный ответ пользователем */
    selected?: Maybe<Scalars['Boolean']>;
    /** Текст ответа */
    text?: Maybe<Scalars['String']>;
};

export type DictationQuestion = {
    __typename?: 'DictationQuestion';
    /** Список ответов. Порядок ответов не меняется */
    answers?: Maybe<Array<DictationAnswer>>;
    /** Кол-во верных ответов, чтобы отобразить checkbox или radio ввод */
    correctAnswersCount?: Maybe<Scalars['Int']>;
    dictationId?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    photoIds?: Maybe<Array<Scalars['ID']>>;
    /** Список картинок в вопросе */
    photos?: Maybe<Array<File>>;
    /** Текст вопроса */
    text?: Maybe<Scalars['String']>;
};

export type DictationResultByUser = {
    __typename?: 'DictationResultByUser';
    dictationId?: Maybe<Scalars['ID']>;
    eventId?: Maybe<Scalars['ID']>;
    eventName?: Maybe<Scalars['String']>;
    questions?: Maybe<Array<Maybe<DictationQuestion>>>;
    resultScore?: Maybe<Scalars['Float']>;
};

export type DictationResultsByUserCollection = {
    __typename?: 'DictationResultsByUserCollection';
    page?: Maybe<Paging>;
    results?: Maybe<Array<Maybe<DictationResultsByUserItem>>>;
};

export type DictationResultsByUserFilter = {
    userId?: Maybe<Scalars['ID']>;
};

export type DictationResultsByUserItem = {
    __typename?: 'DictationResultsByUserItem';
    dictationId?: Maybe<Scalars['ID']>;
    eventId?: Maybe<Scalars['ID']>;
    eventName?: Maybe<Scalars['String']>;
    finishedAt?: Maybe<Scalars['Time']>;
    resultScore?: Maybe<Scalars['Float']>;
    startedAt?: Maybe<Scalars['Time']>;
};

export type DictationResultsByUserOrder = {
    eventName?: Maybe<SortDirection>;
    resultScore?: Maybe<SortDirection>;
    startedAt?: Maybe<SortDirection>;
};

export type DictationUser = {
    __typename?: 'DictationUser';
    dictationId?: Maybe<Scalars['ID']>;
    eventId?: Maybe<Scalars['ID']>;
    eventName?: Maybe<Scalars['String']>;
    /** Дата завершения диктанта */
    finishedAt?: Maybe<Scalars['Time']>;
    /** Набранные баллы пользователя за диктант */
    resultScore?: Maybe<Scalars['Float']>;
    /** Дата начала диктанта */
    startedAt?: Maybe<Scalars['Time']>;
    user?: Maybe<User>;
};

export type DictationUsersCollection = {
    __typename?: 'DictationUsersCollection';
    page?: Maybe<Paging>;
    users?: Maybe<Array<DictationUser>>;
};

export type DictationUsersFilter = {
    dictationId?: Maybe<Scalars['ID']>;
    eventId?: Maybe<Scalars['ID']>;
    locationId?: Maybe<Scalars['ID']>;
    /** Фильтр по ФИО пользователя */
    name?: Maybe<Scalars['String']>;
    regionId?: Maybe<Scalars['ID']>;
};

export type DictationUsersOrder = {
    /** Сортировка по населенному пункту пользователя */
    location?: Maybe<SortDirection>;
    /** Сортировка по ФИО пользователя */
    name?: Maybe<SortDirection>;
    /** Сортировка по региону пользователя */
    region?: Maybe<SortDirection>;
    /** Сортировка по набранным баллам за диктант */
    resultScore?: Maybe<SortDirection>;
    /** Сортировка по имени образовательной организации пользователя */
    schoolName?: Maybe<SortDirection>;
    /** Сортировка по дате начала диктанта */
    startedAt?: Maybe<SortDirection>;
};

export type Event = {
    __typename?: 'Event';
    /** Активный диктант */
    activeDictation?: Maybe<Dictation>;
    /** Баллы текущего пользователя по мероприятию */
    currentUserResultScore?: Maybe<Scalars['Float']>;
    /** Закончился ли диктант по мероприятию */
    finished?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    /**
     * Ссылка на скачивание сертификата по мероприятию для текущего пользователя.
     * Ссылка будет доступна после прохождения диктанта.
     */
    linkToDownloadCertificate?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    /** Идет ли сейчас диктант по мероприятию */
    running?: Maybe<Scalars['Boolean']>;
    /** Дата начала */
    startAt?: Maybe<Scalars['Time']>;
    /** Флаг начала трансляции */
    streamActive?: Maybe<Scalars['Boolean']>;
    /** URL видео потока */
    streamUrl?: Maybe<Scalars['String']>;
};

export type EventCreate = {
    /** Название */
    name: Scalars['String'];
    /** Дата старта */
    startAt: Scalars['Time'];
};

export type EventUpdate = {
    name?: Maybe<Scalars['String']>;
    startAt?: Maybe<Scalars['Time']>;
};

export type File = {
    __typename?: 'File';
    id?: Maybe<Scalars['ID']>;
    links?: Maybe<FileLinks>;
    mime?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['Int']>;
};

export type FileLinks = {
    __typename?: 'FileLinks';
    origin?: Maybe<Scalars['String']>;
    thumbnail?: Maybe<Scalars['String']>;
};

export type ForgotPasswordReply = {
    __typename?: 'ForgotPasswordReply';
    email?: Maybe<Scalars['String']>;
};

export type GetLocationsParams = {
    filter?: Maybe<LocationFilter>;
};

export type Location = {
    __typename?: 'Location';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    region?: Maybe<Region>;
    regionId?: Maybe<Scalars['ID']>;
};

export type LocationFilter = {
    name?: Maybe<Scalars['String']>;
    regionId?: Maybe<Scalars['ID']>;
};

/** Мутации всякие разные */
export type Mutation = {
    __typename?: 'Mutation';
    /** Начать stream */
    activateStream: Scalars['Boolean'];
    /**
     * Ответить на вопрос по диктанту.
     * Ответить можно только на вопрос активного диктанта.
     */
    answerTheQuestionOfDictation: Scalars['Boolean'];
    /** Изменить пароль текущего пользователя */
    changeCurrentUserPassword: Scalars['Boolean'];
    /** Создать событие */
    createEvent?: Maybe<Event>;
    /** Удалить событие */
    deleteEvent: Scalars['Boolean'];
    /** Удалить файл */
    deleteFile: Scalars['Boolean'];
    /** Авторизация пользователя по его email и паролю */
    login?: Maybe<AuthTokens>;
    /**
     * Перейти к следующему вопросу в диктанте.
     * Вызов этого метода установит новый активный вопрос.
     */
    nextQuestionOfDictation?: Maybe<ActiveDictationQuestion>;
    /**
     * Перейти к предыдущему активному вопросу
     * Вызов этого метода установит новый активный вопрос.
     */
    prevQuestionOfDictation?: Maybe<ActiveDictationQuestion>;
    /** Перевыпуск токена используя `refreshToken` */
    refreshToken?: Maybe<AuthTokens>;
    /**
     * Установить новый пароль, используя токен.
     * Токен передается в ссылке, которая отправляется на почту пользователю.
     */
    resetPassword?: Maybe<AuthTokens>;
    /**
     * Отозвать токен. Отзываются обе пары токенов: access и refresh token.
     * Для отзыва можно передать один из двух токенов.
     */
    revokeToken: Scalars['Boolean'];
    /** Отправить ссылку для восстановления пароля на указанный email. */
    sendForgotPassword: Scalars['Boolean'];
    /** Регистрация пользователя. Опционально указывается ФИО `name`. */
    signup?: Maybe<AuthTokens>;
    /** Запустить диктант */
    startDictationByEvent?: Maybe<Dictation>;
    /** Остановить диктант */
    stopDictationByEvent: Scalars['Boolean'];
    /** Обновить информацию текущего пользователя */
    updateCurrentUser: Scalars['Boolean'];
    /** Обновить событие */
    updateEvent: Scalars['Boolean'];
    /** Загрузить файл по URL */
    uploadFileFromUrl?: Maybe<Scalars['ID']>;
};

/** Мутации всякие разные */
export type MutationActivateStreamArgs = {
    active?: Maybe<Scalars['Boolean']>;
    eventId: Scalars['ID'];
    streamUrl?: Maybe<Scalars['String']>;
};

/** Мутации всякие разные */
export type MutationAnswerTheQuestionOfDictationArgs = {
    answerIds: Array<Scalars['ID']>;
    questionId: Scalars['ID'];
};

/** Мутации всякие разные */
export type MutationChangeCurrentUserPasswordArgs = {
    newPassword: Scalars['String'];
    oldPassword: Scalars['String'];
};

/** Мутации всякие разные */
export type MutationCreateEventArgs = {
    event: EventCreate;
};

/** Мутации всякие разные */
export type MutationDeleteEventArgs = {
    eventId: Scalars['ID'];
};

/** Мутации всякие разные */
export type MutationDeleteFileArgs = {
    fileId: Scalars['ID'];
};

/** Мутации всякие разные */
export type MutationLoginArgs = {
    email: Scalars['String'];
    password: Scalars['String'];
};

/** Мутации всякие разные */
export type MutationNextQuestionOfDictationArgs = {
    dictationId: Scalars['ID'];
};

/** Мутации всякие разные */
export type MutationPrevQuestionOfDictationArgs = {
    dictationId: Scalars['ID'];
};

/** Мутации всякие разные */
export type MutationRefreshTokenArgs = {
    refreshToken: Scalars['String'];
};

/** Мутации всякие разные */
export type MutationResetPasswordArgs = {
    password: Scalars['String'];
    token: Scalars['String'];
};

/** Мутации всякие разные */
export type MutationRevokeTokenArgs = {
    accessToken?: Maybe<Scalars['String']>;
    refreshToken?: Maybe<Scalars['String']>;
};

/** Мутации всякие разные */
export type MutationSendForgotPasswordArgs = {
    email: Scalars['String'];
};

/** Мутации всякие разные */
export type MutationSignupArgs = {
    user: UserSignup;
};

/** Мутации всякие разные */
export type MutationStartDictationByEventArgs = {
    eventId: Scalars['ID'];
    questionsCount: Scalars['Int'];
};

/** Мутации всякие разные */
export type MutationStopDictationByEventArgs = {
    eventId: Scalars['ID'];
};

/** Мутации всякие разные */
export type MutationUpdateCurrentUserArgs = {
    doc: UserUpdate;
};

/** Мутации всякие разные */
export type MutationUpdateEventArgs = {
    event: EventUpdate;
    eventId: Scalars['ID'];
};

/** Мутации всякие разные */
export type MutationUploadFileFromUrlArgs = {
    url: Scalars['String'];
};

export type Paging = {
    __typename?: 'Paging';
    currentPage?: Maybe<Scalars['Int']>;
    itemsPerPage?: Maybe<Scalars['Int']>;
    totalItems?: Maybe<Scalars['Int']>;
};

export type PagingInput = {
    itemsPerPage?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    selectAllItems?: Maybe<Scalars['Boolean']>;
};

/** Запросы для извлечения информации */
export type Query = {
    __typename?: 'Query';
    _service: _Service;
    /** Получить активный диктант по мероприятию */
    getActiveDictationByEvent?: Maybe<Dictation>;
    /** Получить активный вопрос по диктанту */
    getActiveDictationQuestion?: Maybe<ActiveDictationQuestion>;
    /** Получить информацию по текущему пользователю */
    getCurrentUser?: Maybe<User>;
    /** Получить результаты пользователя по диктанту */
    getDictationResultByUser?: Maybe<DictationResultByUser>;
    /** Получить список результатов пользователя по всем пройденным диктантам/мероприятиям */
    getDictationResultsCollectionByUser?: Maybe<DictationResultsByUserCollection>;
    /** Получить список пользователей, которые проходили диктант */
    getDictationUsersCollection?: Maybe<DictationUsersCollection>;
    /** Получить информацию по событию */
    getEvent?: Maybe<Event>;
    /** Получить все мероприятия */
    getEvents?: Maybe<Array<Event>>;
    /** Получить события владельцаы */
    getEventsByOwner?: Maybe<Array<Event>>;
    /** Получить список событий */
    getEventsByUser?: Maybe<Array<Event>>;
    /** Получить информацию о файле */
    getFileInfo?: Maybe<File>;
    getInfoByResetPasswordToken?: Maybe<ResetPasswordTokenInfo>;
    /**
     * Ссылка на скачивание сертификата по мероприятию для указанного пользователя.
     * Ссылка доступна только после прохождения диктанта.
     */
    getLinkToDownloadCertificate?: Maybe<Scalars['String']>;
    /**
     * [public]
     * Получить список населенных пунктов РФ по ID региона
     */
    getLocationsByRegion?: Maybe<Array<Location>>;
    /**
     * [public]
     * Получить список регионов РФ
     */
    getRegions?: Maybe<Array<Region>>;
    /** Получить информацию о пользователе по его ID */
    getUser?: Maybe<User>;
};

/** Запросы для извлечения информации */
export type QueryGetActiveDictationByEventArgs = {
    eventId: Scalars['ID'];
};

/** Запросы для извлечения информации */
export type QueryGetActiveDictationQuestionArgs = {
    dictationId: Scalars['ID'];
};

/** Запросы для извлечения информации */
export type QueryGetDictationResultByUserArgs = {
    dictationId: Scalars['ID'];
    userId: Scalars['ID'];
};

/** Запросы для извлечения информации */
export type QueryGetDictationResultsCollectionByUserArgs = {
    filter?: Maybe<DictationResultsByUserFilter>;
    order?: Maybe<DictationResultsByUserOrder>;
    page?: Maybe<PagingInput>;
};

/** Запросы для извлечения информации */
export type QueryGetDictationUsersCollectionArgs = {
    filter?: Maybe<DictationUsersFilter>;
    order?: Maybe<DictationUsersOrder>;
    page?: Maybe<PagingInput>;
};

/** Запросы для извлечения информации */
export type QueryGetEventArgs = {
    eventId: Scalars['ID'];
};

/** Запросы для извлечения информации */
export type QueryGetFileInfoArgs = {
    fileId: Scalars['ID'];
};

/** Запросы для извлечения информации */
export type QueryGetInfoByResetPasswordTokenArgs = {
    token: Scalars['String'];
};

/** Запросы для извлечения информации */
export type QueryGetLinkToDownloadCertificateArgs = {
    eventId: Scalars['ID'];
    userId: Scalars['ID'];
};

/** Запросы для извлечения информации */
export type QueryGetLocationsByRegionArgs = {
    regionId: Scalars['ID'];
};

/** Запросы для извлечения информации */
export type QueryGetUserArgs = {
    id: Scalars['ID'];
};

export type Region = {
    __typename?: 'Region';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type ResetPasswordTokenInfo = {
    __typename?: 'ResetPasswordTokenInfo';
    /** ФИО пользователя */
    name?: Maybe<Scalars['String']>;
};

/** Направление сортировки */
export enum SortDirection {
    /** По возрастанию */
    asc = 'asc',
    /** По убыванию */
    desc = 'desc',
}

/** Подписки для получения обновлений данных в реальном времени */
export type Subscription = {
    __typename?: 'Subscription';
    /** Следить за изменением активного вопроса по диктанту. */
    watchActiveQuestionOfDictation?: Maybe<ActiveDictationQuestion>;
    /** Следить за мероприятием */
    watchEvent?: Maybe<Event>;
};

/** Подписки для получения обновлений данных в реальном времени */
export type SubscriptionWatchActiveQuestionOfDictationArgs = {
    dictationId: Scalars['ID'];
};

/** Подписки для получения обновлений данных в реальном времени */
export type SubscriptionWatchEventArgs = {
    eventId: Scalars['ID'];
};

export type User = {
    __typename?: 'User';
    email?: Maybe<Scalars['String']>;
    /** Показывать ли форму приветствия после регистрации */
    greetingPassed?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    /** Населенный пункт */
    location?: Maybe<Location>;
    /** ID населенного пункта */
    locationId?: Maybe<Scalars['ID']>;
    /** ФИО */
    name?: Maybe<Scalars['String']>;
    /** Номер телефона */
    phone?: Maybe<Scalars['String']>;
    role?: Maybe<UserRole>;
    /** Название школы */
    schoolName?: Maybe<Scalars['String']>;
};

export enum UserRole {
    /** Роль администратора */
    admin = 'admin',
    /** Роль участника диктанта */
    testable = 'testable',
}

export type UserSignup = {
    email: Scalars['String'];
    name?: Maybe<Scalars['String']>;
    password: Scalars['String'];
    role?: Maybe<UserSignupRole>;
};

export enum UserSignupRole {
    /** Роль участника диктанта */
    testable = 'testable',
}

/**
 * Поля которые можно обновить у пользователя.
 * Не обязательно указывать все поля, можно указать только те, которые следует обновить.
 * Если поле будет не указано или иметь значение null, то оно обновлено не будет.
 */
export type UserUpdate = {
    email?: Maybe<Scalars['String']>;
    greetingPassed?: Maybe<Scalars['Boolean']>;
    locationId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    schoolName?: Maybe<Scalars['String']>;
};

export type _Service = {
    __typename?: '_Service';
    sdl?: Maybe<Scalars['String']>;
};

export type AuthTokensFragment = { __typename?: 'AuthTokens' } & Pick<
    AuthTokens,
    'accessToken' | 'refreshToken'
>;

export type ChangeCurrentUserPasswordMutationVariables = Exact<{
    oldPassword: Scalars['String'];
    newPassword: Scalars['String'];
}>;

export type ChangeCurrentUserPasswordMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'changeCurrentUserPassword'
>;

export type LoginMutationVariables = Exact<{
    email: Scalars['String'];
    password: Scalars['String'];
}>;

export type LoginMutationType = { __typename?: 'Mutation' } & {
    login?: Maybe<{ __typename?: 'AuthTokens' } & AuthTokensFragment>;
};

export type RefreshTokenMutationVariables = Exact<{
    refreshToken: Scalars['String'];
}>;

export type RefreshTokenMutationType = { __typename?: 'Mutation' } & {
    refreshToken?: Maybe<{ __typename?: 'AuthTokens' } & AuthTokensFragment>;
};

export type ResetPasswordMutationVariables = Exact<{
    token: Scalars['String'];
    password: Scalars['String'];
}>;

export type ResetPasswordMutationType = { __typename?: 'Mutation' } & {
    resetPassword?: Maybe<{ __typename?: 'AuthTokens' } & AuthTokensFragment>;
};

export type RevokeTokenMutationVariables = Exact<{
    refreshToken: Scalars['String'];
}>;

export type RevokeTokenMutationType = { __typename?: 'Mutation' } & Pick<Mutation, 'revokeToken'>;

export type SendForgotPasswordMutationVariables = Exact<{
    email: Scalars['String'];
}>;

export type SendForgotPasswordMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'sendForgotPassword'
>;

export type SignupMutationVariables = Exact<{
    user: UserSignup;
}>;

export type SignupMutationType = { __typename?: 'Mutation' } & {
    signup?: Maybe<{ __typename?: 'AuthTokens' } & AuthTokensFragment>;
};

export type UpdateCurrentUserMutationVariables = Exact<{
    doc: UserUpdate;
}>;

export type UpdateCurrentUserMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'updateCurrentUser'
>;

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQueryType = { __typename?: 'Query' } & {
    getCurrentUser?: Maybe<{ __typename?: 'User' } & UserFragment>;
};

export type GetInfoByResetPasswordTokenQueryVariables = Exact<{
    token: Scalars['String'];
}>;

export type GetInfoByResetPasswordTokenQueryType = { __typename?: 'Query' } & {
    getInfoByResetPasswordToken?: Maybe<
        { __typename?: 'ResetPasswordTokenInfo' } & Pick<ResetPasswordTokenInfo, 'name'>
    >;
};

export type DictationAnswerFragment = { __typename?: 'DictationAnswer' } & Pick<
    DictationAnswer,
    'id' | 'photoId' | 'text' | 'isCorrect' | 'selected'
> & { photo?: Maybe<{ __typename?: 'File' } & FileFragment> };

export type DictationQuestionFragment = { __typename?: 'DictationQuestion' } & Pick<
    DictationQuestion,
    'id' | 'dictationId' | 'photoIds' | 'text' | 'correctAnswersCount'
> & {
        photos?: Maybe<Array<{ __typename?: 'File' } & FileFragment>>;
        answers?: Maybe<Array<{ __typename?: 'DictationAnswer' } & DictationAnswerFragment>>;
    };

export type DictationFragment = { __typename?: 'Dictation' } & Pick<
    Dictation,
    | 'id'
    | 'eventId'
    | 'questionsCount'
    | 'activeQuestionIndex'
    | 'startedAt'
    | 'finished'
    | 'finishedAt'
> & { activeQuestion?: Maybe<{ __typename?: 'DictationQuestion' } & DictationQuestionFragment> };

export type AnswerTheQuestionOfDictationMutationVariables = Exact<{
    questionId: Scalars['ID'];
    answerIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AnswerTheQuestionOfDictationMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'answerTheQuestionOfDictation'
>;

export type NextQuestionOfDictationMutationVariables = Exact<{
    dictationId: Scalars['ID'];
}>;

export type NextQuestionOfDictationMutationType = { __typename?: 'Mutation' } & {
    nextQuestionOfDictation?: Maybe<
        { __typename?: 'ActiveDictationQuestion' } & Pick<
            ActiveDictationQuestion,
            'questionIndex'
        > & { question?: Maybe<{ __typename?: 'DictationQuestion' } & DictationQuestionFragment> }
    >;
};

export type PrevQuestionOfDictationMutationVariables = Exact<{
    dictationId: Scalars['ID'];
}>;

export type PrevQuestionOfDictationMutationType = { __typename?: 'Mutation' } & {
    prevQuestionOfDictation?: Maybe<
        { __typename?: 'ActiveDictationQuestion' } & Pick<
            ActiveDictationQuestion,
            'questionIndex'
        > & { question?: Maybe<{ __typename?: 'DictationQuestion' } & DictationQuestionFragment> }
    >;
};

export type StartDictationByEventMutationVariables = Exact<{
    eventId: Scalars['ID'];
    questionsCount: Scalars['Int'];
}>;

export type StartDictationByEventMutationType = { __typename?: 'Mutation' } & {
    startDictationByEvent?: Maybe<{ __typename?: 'Dictation' } & DictationFragment>;
};

export type StopDictationByEventMutationVariables = Exact<{
    eventId: Scalars['ID'];
}>;

export type StopDictationByEventMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'stopDictationByEvent'
>;

export type GetActiveDictationByEventQueryVariables = Exact<{
    eventId: Scalars['ID'];
}>;

export type GetActiveDictationByEventQueryType = { __typename?: 'Query' } & {
    getActiveDictationByEvent?: Maybe<{ __typename?: 'Dictation' } & DictationFragment>;
};

export type GetActiveDictationQuestionQueryVariables = Exact<{
    dictationId: Scalars['ID'];
}>;

export type GetActiveDictationQuestionQueryType = { __typename?: 'Query' } & {
    getActiveDictationQuestion?: Maybe<
        { __typename?: 'ActiveDictationQuestion' } & Pick<
            ActiveDictationQuestion,
            'questionIndex'
        > & { question?: Maybe<{ __typename?: 'DictationQuestion' } & DictationQuestionFragment> }
    >;
};

export type WatchActiveQuestionOfDictationSubscriptionVariables = Exact<{
    dictationId: Scalars['ID'];
}>;

export type WatchActiveQuestionOfDictationSubscriptionType = { __typename?: 'Subscription' } & {
    watchActiveQuestionOfDictation?: Maybe<
        { __typename?: 'ActiveDictationQuestion' } & Pick<
            ActiveDictationQuestion,
            'questionIndex'
        > & { question?: Maybe<{ __typename?: 'DictationQuestion' } & DictationQuestionFragment> }
    >;
};

export type EventFragment = { __typename?: 'Event' } & Pick<
    Event,
    | 'id'
    | 'name'
    | 'startAt'
    | 'streamUrl'
    | 'streamActive'
    | 'running'
    | 'finished'
    | 'currentUserResultScore'
    | 'linkToDownloadCertificate'
> & { activeDictation?: Maybe<{ __typename?: 'Dictation' } & DictationFragment> };

export type CreateEventMutationVariables = Exact<{
    event: EventCreate;
}>;

export type CreateEventMutationType = { __typename?: 'Mutation' } & {
    createEvent?: Maybe<{ __typename?: 'Event' } & EventFragment>;
};

export type DeleteEventMutationVariables = Exact<{
    eventId: Scalars['ID'];
}>;

export type DeleteEventMutationType = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteEvent'>;

export type UpdateEventMutationVariables = Exact<{
    eventId: Scalars['ID'];
    event: EventUpdate;
}>;

export type UpdateEventMutationType = { __typename?: 'Mutation' } & Pick<Mutation, 'updateEvent'>;

export type GetEventQueryVariables = Exact<{
    eventId: Scalars['ID'];
}>;

export type GetEventQueryType = { __typename?: 'Query' } & {
    getEvent?: Maybe<{ __typename?: 'Event' } & EventFragment>;
};

export type GetEventsByOwnerQueryVariables = Exact<{ [key: string]: never }>;

export type GetEventsByOwnerQueryType = { __typename?: 'Query' } & {
    getEventsByOwner?: Maybe<Array<{ __typename?: 'Event' } & EventFragment>>;
};

export type GetEventsByUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetEventsByUserQueryType = { __typename?: 'Query' } & {
    getEventsByUser?: Maybe<Array<{ __typename?: 'Event' } & EventFragment>>;
};

export type GetEventsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEventsQueryType = { __typename?: 'Query' } & {
    getEvents?: Maybe<Array<{ __typename?: 'Event' } & EventFragment>>;
};

export type WatchEventSubscriptionVariables = Exact<{
    eventId: Scalars['ID'];
}>;

export type WatchEventSubscriptionType = { __typename?: 'Subscription' } & {
    watchEvent?: Maybe<{ __typename?: 'Event' } & EventFragment>;
};

export type ActivateStreamMutationVariables = Exact<{
    eventId: Scalars['ID'];
    active?: Maybe<Scalars['Boolean']>;
    streamUrl?: Maybe<Scalars['String']>;
}>;

export type ActivateStreamMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'activateStream'
>;

export type FileFragment = { __typename?: 'File' } & Pick<File, 'id' | 'name' | 'mime' | 'size'> & {
        links?: Maybe<{ __typename?: 'FileLinks' } & Pick<FileLinks, 'origin' | 'thumbnail'>>;
    };

export type PagingFragment = { __typename?: 'Paging' } & Pick<
    Paging,
    'currentPage' | 'itemsPerPage' | 'totalItems'
>;

export type DeleteFileMutationVariables = Exact<{
    fileId: Scalars['ID'];
}>;

export type DeleteFileMutationType = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteFile'>;

export type UploadFileFromUrlMutationVariables = Exact<{
    url: Scalars['String'];
}>;

export type UploadFileFromUrlMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'uploadFileFromUrl'
>;

export type GetFileInfoQueryVariables = Exact<{
    fileId: Scalars['ID'];
}>;

export type GetFileInfoQueryType = { __typename?: 'Query' } & {
    getFileInfo?: Maybe<{ __typename?: 'File' } & FileFragment>;
};

export type GetLocationsByRegionQueryVariables = Exact<{
    regionId: Scalars['ID'];
}>;

export type GetLocationsByRegionQueryType = { __typename?: 'Query' } & {
    getLocationsByRegion?: Maybe<
        Array<
            { __typename?: 'Location' } & Pick<Location, 'id' | 'regionId' | 'name'> & {
                    region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
                }
        >
    >;
};

export type GetRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRegionsQueryType = { __typename?: 'Query' } & {
    getRegions?: Maybe<Array<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>>;
};

export type DictationResultsByUserItemFragment = {
    __typename?: 'DictationResultsByUserItem';
} & Pick<
    DictationResultsByUserItem,
    'eventId' | 'eventName' | 'dictationId' | 'startedAt' | 'finishedAt' | 'resultScore'
>;

export type DictationUserFragment = { __typename?: 'DictationUser' } & Pick<
    DictationUser,
    'dictationId' | 'eventId' | 'eventName' | 'startedAt' | 'finishedAt' | 'resultScore'
> & {
        user?: Maybe<
            { __typename?: 'User' } & Pick<
                User,
                | 'id'
                | 'name'
                | 'email'
                | 'role'
                | 'locationId'
                | 'phone'
                | 'schoolName'
                | 'greetingPassed'
            > & {
                    location?: Maybe<
                        { __typename?: 'Location' } & Pick<Location, 'id' | 'regionId' | 'name'> & {
                                region?: Maybe<
                                    { __typename?: 'Region' } & Pick<Region, 'id' | 'name'>
                                >;
                            }
                    >;
                }
        >;
    };

export type UserFragment = { __typename?: 'User' } & Pick<
    User,
    'id' | 'name' | 'email' | 'role' | 'locationId' | 'phone' | 'schoolName' | 'greetingPassed'
> & {
        location?: Maybe<
            { __typename?: 'Location' } & Pick<Location, 'id' | 'regionId' | 'name'> & {
                    region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
                }
        >;
    };

export type GetDictationResultByUserQueryVariables = Exact<{
    dictationId: Scalars['ID'];
    userId: Scalars['ID'];
}>;

export type GetDictationResultByUserQueryType = { __typename?: 'Query' } & {
    getDictationResultByUser?: Maybe<
        { __typename?: 'DictationResultByUser' } & Pick<
            DictationResultByUser,
            'eventId' | 'eventName' | 'dictationId' | 'resultScore'
        > & {
                questions?: Maybe<
                    Array<Maybe<{ __typename?: 'DictationQuestion' } & DictationQuestionFragment>>
                >;
            }
    >;
};

export type GetDictationResultsCollectionByUserQueryVariables = Exact<{
    filter?: Maybe<DictationResultsByUserFilter>;
    order?: Maybe<DictationResultsByUserOrder>;
    page?: Maybe<PagingInput>;
}>;

export type GetDictationResultsCollectionByUserQueryType = { __typename?: 'Query' } & {
    getDictationResultsCollectionByUser?: Maybe<
        { __typename?: 'DictationResultsByUserCollection' } & {
            results?: Maybe<
                Array<
                    Maybe<
                        {
                            __typename?: 'DictationResultsByUserItem';
                        } & DictationResultsByUserItemFragment
                    >
                >
            >;
            page?: Maybe<{ __typename?: 'Paging' } & PagingFragment>;
        }
    >;
};

export type GetDictationUsersCollectionQueryVariables = Exact<{
    filter?: Maybe<DictationUsersFilter>;
    order?: Maybe<DictationUsersOrder>;
    page?: Maybe<PagingInput>;
}>;

export type GetDictationUsersCollectionQueryType = { __typename?: 'Query' } & {
    getDictationUsersCollection?: Maybe<
        { __typename?: 'DictationUsersCollection' } & {
            users?: Maybe<Array<{ __typename?: 'DictationUser' } & DictationUserFragment>>;
            page?: Maybe<{ __typename?: 'Paging' } & PagingFragment>;
        }
    >;
};

export type GetUserQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetUserQueryType = { __typename?: 'Query' } & {
    getUser?: Maybe<{ __typename?: 'User' } & UserFragment>;
};

export const AuthTokensFragmentDoc = gql`
    fragment AuthTokens on AuthTokens {
        accessToken
        refreshToken
    }
`;
export const FileFragmentDoc = gql`
    fragment File on File {
        id
        name
        mime
        size
        links {
            origin
            thumbnail
        }
    }
`;
export const DictationAnswerFragmentDoc = gql`
    fragment DictationAnswer on DictationAnswer {
        id
        photoId
        text
        photo {
            ...File
        }
        isCorrect
        selected
    }
    ${FileFragmentDoc}
`;
export const DictationQuestionFragmentDoc = gql`
    fragment DictationQuestion on DictationQuestion {
        id
        dictationId
        photoIds
        text
        photos {
            ...File
        }
        answers {
            ...DictationAnswer
        }
        correctAnswersCount
    }
    ${FileFragmentDoc}
    ${DictationAnswerFragmentDoc}
`;
export const DictationFragmentDoc = gql`
    fragment Dictation on Dictation {
        id
        eventId
        questionsCount
        activeQuestion {
            ...DictationQuestion
        }
        activeQuestionIndex
        startedAt
        finished
        finishedAt
    }
    ${DictationQuestionFragmentDoc}
`;
export const EventFragmentDoc = gql`
    fragment Event on Event {
        id
        name
        startAt
        streamUrl
        streamActive
        running
        finished
        activeDictation {
            ...Dictation
        }
        currentUserResultScore
        linkToDownloadCertificate
    }
    ${DictationFragmentDoc}
`;
export const PagingFragmentDoc = gql`
    fragment Paging on Paging {
        currentPage
        itemsPerPage
        totalItems
    }
`;
export const DictationResultsByUserItemFragmentDoc = gql`
    fragment DictationResultsByUserItem on DictationResultsByUserItem {
        eventId
        eventName
        dictationId
        startedAt
        finishedAt
        resultScore
    }
`;
export const DictationUserFragmentDoc = gql`
    fragment DictationUser on DictationUser {
        user {
            id
            name
            email
            role
            locationId
            location {
                id
                regionId
                name
                region {
                    id
                    name
                }
            }
            phone
            schoolName
            greetingPassed
        }
        dictationId
        eventId
        eventName
        startedAt
        finishedAt
        resultScore
    }
`;
export const UserFragmentDoc = gql`
    fragment User on User {
        id
        name
        email
        role
        locationId
        location {
            id
            regionId
            name
            region {
                id
                name
            }
        }
        phone
        schoolName
        greetingPassed
    }
`;
export const ChangeCurrentUserPasswordDocument = gql`
    mutation ChangeCurrentUserPassword($oldPassword: String!, $newPassword: String!) {
        changeCurrentUserPassword(oldPassword: $oldPassword, newPassword: $newPassword)
    }
`;
export type ChangeCurrentUserPasswordMutationFn = Apollo.MutationFunction<
    ChangeCurrentUserPasswordMutationType,
    ChangeCurrentUserPasswordMutationVariables
>;
export type ChangeCurrentUserPasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangeCurrentUserPasswordMutationType,
    ChangeCurrentUserPasswordMutationVariables
>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            ...AuthTokens
        }
    }
    ${AuthTokensFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutationType, LoginMutationVariables>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
    LoginMutationType,
    LoginMutationVariables
>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String!) {
        refreshToken(refreshToken: $refreshToken) {
            ...AuthTokens
        }
    }
    ${AuthTokensFragmentDoc}
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
    RefreshTokenMutationType,
    RefreshTokenMutationVariables
>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
    RefreshTokenMutationType,
    RefreshTokenMutationVariables
>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $password: String!) {
        resetPassword(token: $token, password: $password) {
            ...AuthTokens
        }
    }
    ${AuthTokensFragmentDoc}
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
    ResetPasswordMutationType,
    ResetPasswordMutationVariables
>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordMutationType,
    ResetPasswordMutationVariables
>;
export const RevokeTokenDocument = gql`
    mutation RevokeToken($refreshToken: String!) {
        revokeToken(refreshToken: $refreshToken)
    }
`;
export type RevokeTokenMutationFn = Apollo.MutationFunction<
    RevokeTokenMutationType,
    RevokeTokenMutationVariables
>;
export type RevokeTokenMutationOptions = Apollo.BaseMutationOptions<
    RevokeTokenMutationType,
    RevokeTokenMutationVariables
>;
export const SendForgotPasswordDocument = gql`
    mutation SendForgotPassword($email: String!) {
        sendForgotPassword(email: $email)
    }
`;
export type SendForgotPasswordMutationFn = Apollo.MutationFunction<
    SendForgotPasswordMutationType,
    SendForgotPasswordMutationVariables
>;
export type SendForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
    SendForgotPasswordMutationType,
    SendForgotPasswordMutationVariables
>;
export const SignupDocument = gql`
    mutation Signup($user: UserSignup!) {
        signup(user: $user) {
            ...AuthTokens
        }
    }
    ${AuthTokensFragmentDoc}
`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutationType, SignupMutationVariables>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
    SignupMutationType,
    SignupMutationVariables
>;
export const UpdateCurrentUserDocument = gql`
    mutation UpdateCurrentUser($doc: UserUpdate!) {
        updateCurrentUser(doc: $doc)
    }
`;
export type UpdateCurrentUserMutationFn = Apollo.MutationFunction<
    UpdateCurrentUserMutationType,
    UpdateCurrentUserMutationVariables
>;
export type UpdateCurrentUserMutationOptions = Apollo.BaseMutationOptions<
    UpdateCurrentUserMutationType,
    UpdateCurrentUserMutationVariables
>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
        getCurrentUser {
            ...User
        }
    }
    ${UserFragmentDoc}
`;
export const GetInfoByResetPasswordTokenDocument = gql`
    query GetInfoByResetPasswordToken($token: String!) {
        getInfoByResetPasswordToken(token: $token) {
            name
        }
    }
`;
export const AnswerTheQuestionOfDictationDocument = gql`
    mutation AnswerTheQuestionOfDictation($questionId: ID!, $answerIds: [ID!]!) {
        answerTheQuestionOfDictation(questionId: $questionId, answerIds: $answerIds)
    }
`;
export type AnswerTheQuestionOfDictationMutationFn = Apollo.MutationFunction<
    AnswerTheQuestionOfDictationMutationType,
    AnswerTheQuestionOfDictationMutationVariables
>;
export type AnswerTheQuestionOfDictationMutationOptions = Apollo.BaseMutationOptions<
    AnswerTheQuestionOfDictationMutationType,
    AnswerTheQuestionOfDictationMutationVariables
>;
export const NextQuestionOfDictationDocument = gql`
    mutation NextQuestionOfDictation($dictationId: ID!) {
        nextQuestionOfDictation(dictationId: $dictationId) {
            questionIndex
            question {
                ...DictationQuestion
            }
        }
    }
    ${DictationQuestionFragmentDoc}
`;
export type NextQuestionOfDictationMutationFn = Apollo.MutationFunction<
    NextQuestionOfDictationMutationType,
    NextQuestionOfDictationMutationVariables
>;
export type NextQuestionOfDictationMutationOptions = Apollo.BaseMutationOptions<
    NextQuestionOfDictationMutationType,
    NextQuestionOfDictationMutationVariables
>;
export const PrevQuestionOfDictationDocument = gql`
    mutation PrevQuestionOfDictation($dictationId: ID!) {
        prevQuestionOfDictation(dictationId: $dictationId) {
            questionIndex
            question {
                ...DictationQuestion
            }
        }
    }
    ${DictationQuestionFragmentDoc}
`;
export type PrevQuestionOfDictationMutationFn = Apollo.MutationFunction<
    PrevQuestionOfDictationMutationType,
    PrevQuestionOfDictationMutationVariables
>;
export type PrevQuestionOfDictationMutationOptions = Apollo.BaseMutationOptions<
    PrevQuestionOfDictationMutationType,
    PrevQuestionOfDictationMutationVariables
>;
export const StartDictationByEventDocument = gql`
    mutation StartDictationByEvent($eventId: ID!, $questionsCount: Int!) {
        startDictationByEvent(eventId: $eventId, questionsCount: $questionsCount) {
            ...Dictation
        }
    }
    ${DictationFragmentDoc}
`;
export type StartDictationByEventMutationFn = Apollo.MutationFunction<
    StartDictationByEventMutationType,
    StartDictationByEventMutationVariables
>;
export type StartDictationByEventMutationOptions = Apollo.BaseMutationOptions<
    StartDictationByEventMutationType,
    StartDictationByEventMutationVariables
>;
export const StopDictationByEventDocument = gql`
    mutation StopDictationByEvent($eventId: ID!) {
        stopDictationByEvent(eventId: $eventId)
    }
`;
export type StopDictationByEventMutationFn = Apollo.MutationFunction<
    StopDictationByEventMutationType,
    StopDictationByEventMutationVariables
>;
export type StopDictationByEventMutationOptions = Apollo.BaseMutationOptions<
    StopDictationByEventMutationType,
    StopDictationByEventMutationVariables
>;
export const GetActiveDictationByEventDocument = gql`
    query GetActiveDictationByEvent($eventId: ID!) {
        getActiveDictationByEvent(eventId: $eventId) {
            ...Dictation
        }
    }
    ${DictationFragmentDoc}
`;
export const GetActiveDictationQuestionDocument = gql`
    query GetActiveDictationQuestion($dictationId: ID!) {
        getActiveDictationQuestion(dictationId: $dictationId) {
            questionIndex
            question {
                ...DictationQuestion
            }
        }
    }
    ${DictationQuestionFragmentDoc}
`;
export const WatchActiveQuestionOfDictationDocument = gql`
    subscription WatchActiveQuestionOfDictation($dictationId: ID!) {
        watchActiveQuestionOfDictation(dictationId: $dictationId) {
            questionIndex
            question {
                ...DictationQuestion
            }
        }
    }
    ${DictationQuestionFragmentDoc}
`;
export const CreateEventDocument = gql`
    mutation CreateEvent($event: EventCreate!) {
        createEvent(event: $event) {
            ...Event
        }
    }
    ${EventFragmentDoc}
`;
export type CreateEventMutationFn = Apollo.MutationFunction<
    CreateEventMutationType,
    CreateEventMutationVariables
>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<
    CreateEventMutationType,
    CreateEventMutationVariables
>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($eventId: ID!) {
        deleteEvent(eventId: $eventId)
    }
`;
export type DeleteEventMutationFn = Apollo.MutationFunction<
    DeleteEventMutationType,
    DeleteEventMutationVariables
>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<
    DeleteEventMutationType,
    DeleteEventMutationVariables
>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($eventId: ID!, $event: EventUpdate!) {
        updateEvent(eventId: $eventId, event: $event)
    }
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<
    UpdateEventMutationType,
    UpdateEventMutationVariables
>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<
    UpdateEventMutationType,
    UpdateEventMutationVariables
>;
export const GetEventDocument = gql`
    query GetEvent($eventId: ID!) {
        getEvent(eventId: $eventId) {
            ...Event
        }
    }
    ${EventFragmentDoc}
`;
export const GetEventsByOwnerDocument = gql`
    query GetEventsByOwner {
        getEventsByOwner {
            ...Event
        }
    }
    ${EventFragmentDoc}
`;
export const GetEventsByUserDocument = gql`
    query GetEventsByUser {
        getEventsByUser {
            ...Event
        }
    }
    ${EventFragmentDoc}
`;
export const GetEventsDocument = gql`
    query GetEvents {
        getEvents {
            ...Event
        }
    }
    ${EventFragmentDoc}
`;
export const WatchEventDocument = gql`
    subscription WatchEvent($eventId: ID!) {
        watchEvent(eventId: $eventId) {
            ...Event
        }
    }
    ${EventFragmentDoc}
`;
export const ActivateStreamDocument = gql`
    mutation ActivateStream($eventId: ID!, $active: Boolean, $streamUrl: String) {
        activateStream(eventId: $eventId, active: $active, streamUrl: $streamUrl)
    }
`;
export type ActivateStreamMutationFn = Apollo.MutationFunction<
    ActivateStreamMutationType,
    ActivateStreamMutationVariables
>;
export type ActivateStreamMutationOptions = Apollo.BaseMutationOptions<
    ActivateStreamMutationType,
    ActivateStreamMutationVariables
>;
export const DeleteFileDocument = gql`
    mutation DeleteFile($fileId: ID!) {
        deleteFile(fileId: $fileId)
    }
`;
export type DeleteFileMutationFn = Apollo.MutationFunction<
    DeleteFileMutationType,
    DeleteFileMutationVariables
>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<
    DeleteFileMutationType,
    DeleteFileMutationVariables
>;
export const UploadFileFromUrlDocument = gql`
    mutation UploadFileFromUrl($url: String!) {
        uploadFileFromUrl(url: $url)
    }
`;
export type UploadFileFromUrlMutationFn = Apollo.MutationFunction<
    UploadFileFromUrlMutationType,
    UploadFileFromUrlMutationVariables
>;
export type UploadFileFromUrlMutationOptions = Apollo.BaseMutationOptions<
    UploadFileFromUrlMutationType,
    UploadFileFromUrlMutationVariables
>;
export const GetFileInfoDocument = gql`
    query GetFileInfo($fileId: ID!) {
        getFileInfo(fileId: $fileId) {
            ...File
        }
    }
    ${FileFragmentDoc}
`;
export const GetLocationsByRegionDocument = gql`
    query GetLocationsByRegion($regionId: ID!) {
        getLocationsByRegion(regionId: $regionId) {
            id
            regionId
            name
            region {
                id
                name
            }
        }
    }
`;
export const GetRegionsDocument = gql`
    query GetRegions {
        getRegions {
            id
            name
        }
    }
`;
export const GetDictationResultByUserDocument = gql`
    query GetDictationResultByUser($dictationId: ID!, $userId: ID!) {
        getDictationResultByUser(dictationId: $dictationId, userId: $userId) {
            eventId
            eventName
            dictationId
            resultScore
            questions {
                ...DictationQuestion
            }
        }
    }
    ${DictationQuestionFragmentDoc}
`;
export const GetDictationResultsCollectionByUserDocument = gql`
    query GetDictationResultsCollectionByUser(
        $filter: DictationResultsByUserFilter
        $order: DictationResultsByUserOrder
        $page: PagingInput
    ) {
        getDictationResultsCollectionByUser(filter: $filter, order: $order, page: $page)
            @connection(key: "getDictationResultsCollectionByUser") {
            results {
                ...DictationResultsByUserItem
            }
            page {
                ...Paging
            }
        }
    }
    ${DictationResultsByUserItemFragmentDoc}
    ${PagingFragmentDoc}
`;
export const GetDictationUsersCollectionDocument = gql`
    query GetDictationUsersCollection(
        $filter: DictationUsersFilter
        $order: DictationUsersOrder
        $page: PagingInput
    ) {
        getDictationUsersCollection(filter: $filter, order: $order, page: $page)
            @connection(key: "getDictationUsersCollection") {
            users {
                ...DictationUser
            }
            page {
                ...Paging
            }
        }
    }
    ${DictationUserFragmentDoc}
    ${PagingFragmentDoc}
`;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
        getUser(id: $id) {
            ...User
        }
    }
    ${UserFragmentDoc}
`;
