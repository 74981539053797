import { gql } from '@apollo/client';

import { AuthTokensFragment } from '../fragments';

export const SignupMutation = gql`
    mutation Signup($user: UserSignup!) {
        signup(user: $user) {
            ...AuthTokens
        }
    }
    ${AuthTokensFragment}
`;
