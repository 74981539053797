import React from 'react';

import { ReactComponent as WelcomeLogoIcon } from './source/welcome-logo.svg';
import { Root } from './shared/styled';

import type { IconProps } from './shared/types';

const WelcomeLogo = (props: IconProps): React.ReactElement => {
    return (
        <Root {...props}>
            <WelcomeLogoIcon />
        </Root>
    );
};

export { WelcomeLogo };
