import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { FileLinksEntity } from '@modules/shared/entities/file-links-entity';

import type { File } from '@modules/types/graphql';

export type Entity = Partial<File> | null;

@DecribeEntity('FileEntity')
class FileEntity extends BaseEntity {
    id: string;
    name: string;
    mime: string;
    size: number;

    @Relation(() => FileLinksEntity)
    links: FileLinksEntity;

    constructor(file: Entity) {
        super(file);

        this.id = file?.id ?? '';
        this.name = file?.name ?? '';
        this.mime = file?.mime ?? '';
        this.size = file?.size ?? 0;
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }
}

export { FileEntity };
