import { EntityList } from '@lib/entity';
import { DictationQuestionEntity } from './dictation-question-entity';

import type { Entity } from './dictation-question-entity';

class DictationQuestionEntityList extends EntityList<DictationQuestionEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(DictationQuestionEntity, items, count);
    }
}

export { DictationQuestionEntityList };
