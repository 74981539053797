import { gql } from '@apollo/client';

import { FileFragment } from '@modules/shared/graphql/fragments';
import { DictationAnswerFragment } from './dictation-answer';

export const DictationQuestionFragment = gql`
    fragment DictationQuestion on DictationQuestion {
        id
        dictationId
        photoIds
        text
        photos {
            ...File
        }
        answers {
            ...DictationAnswer
        }
        correctAnswersCount
    }
    ${FileFragment}
    ${DictationAnswerFragment}
`;
