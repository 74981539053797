import styled from 'styled-components';
import { black } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';

const FormRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
`;

export const FormStyled = {
    FormColumnList: styled.div`
        display: flex;
        width: 100%;

        ${breakpoints.down('md')} {
            flex-wrap: wrap;
        }

        > * {
            &:not(:last-of-type) {
                padding-right: 24px;

                ${breakpoints.down('md')} {
                    margin-bottom: 24px;
                }

                ${breakpoints.down('xs')} {
                    padding-right: 0;
                }
            }
        }
    `,

    FormColumn: styled.div`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        max-width: 33.333%;

        ${({ fullWidth }: { fullWidth?: boolean }) => `
            max-width: ${fullWidth ? '100%' : '33.333%'};
    `}

        > * {
            &:not(:last-of-type) {
                margin-bottom: 24px;
            }
        }

        ${breakpoints.down('md')} {
            max-width: 50%;
        }

        ${breakpoints.down('xs')} {
            max-width: 100%;

            > * {
                margin-bottom: 24px;
            }
        }
    `,

    FormColumnTitle: styled.div`
        width: 100%;
        margin-bottom: 24px;
        color: ${black[80]};
        font-size: 2.1rem;
        font-weight: 500;
    `,

    FormColumnActions: styled.div`
        padding-top: 30px;
        flex: 0 0 auto;

        ${breakpoints.down('xs')} {
            padding-top: 0;
            width: 100%;
        }
    `,

    FormRow,

    FormRowWithMargin: styled(FormRow)`
        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    `,

    FormRowSubmit: styled(FormRow)`
        > * {
            margin-bottom: 8px;

            &:not(:last-of-type) {
                margin-right: 8px;

                ${breakpoints.down('xs')} {
                    margin-right: 0;
                }
            }

            ${breakpoints.down('xs')} {
                width: 100%;
            }
        }
    `,
};
