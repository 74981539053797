import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { FileEntity } from '@modules/shared/entities';

import type { DictationAnswer } from '@modules/types/graphql';

export type Entity = Partial<DictationAnswer> | null;

@DecribeEntity('DictationAnswerEntity')
class DictationAnswerEntity extends BaseEntity {
    id: string;
    photoId: string;
    text: string;
    isCorrect: boolean;
    selected: boolean;

    @Relation(() => FileEntity)
    photo: FileEntity;

    constructor(dictationAnswer: Entity) {
        super(dictationAnswer);

        this.id = dictationAnswer?.id ?? '';
        this.photoId = dictationAnswer?.photoId ?? '';
        this.text = dictationAnswer?.text ?? '';
        this.isCorrect = dictationAnswer?.isCorrect ?? false;
        this.selected = dictationAnswer?.selected ?? false;
    }
}

export { DictationAnswerEntity };
