export {
    DictationResultsByUserItemFragment,
    DictationUserFragment,
    UserFragment,
} from './fragments';
export {
    GetDictationResultByUserQuery,
    GetDictationResultsCollectionByUserQuery,
    GetDictationUsersCollectionQuery,
    GetUserQuery,
} from './queries';
