import type { SignupMutationVariables } from '@modules/types/graphql';

export type SignupFormValues = {
    email: string;
    password: string;
};

class SignupRequest {
    user: SignupMutationVariables['user'];

    constructor(values: SignupFormValues) {
        this.user = {
            email: values.email,
            password: values.password,
        };
    }
}

export { SignupRequest };
