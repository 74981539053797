import { gql } from '@apollo/client';

import { UserFragment } from '@modules/users/graphql/fragments';

export const GetUserQuery = gql`
    query GetUser($id: ID!) {
        getUser(id: $id) {
            ...User
        }
    }
    ${UserFragment}
`;
