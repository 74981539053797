import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { FileLinks } from '@modules/types/graphql';

export type Entity = Partial<FileLinks> | null;

@DecribeEntity('FileLinksEntity')
class FileLinksEntity extends BaseEntity {
    origin: string;
    thumbnail: string;

    constructor(fileLink: Entity) {
        super(fileLink);

        this.origin = fileLink?.origin ?? '';
        this.thumbnail = fileLink?.thumbnail ?? '';
    }
}

export { FileLinksEntity };
