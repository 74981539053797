import { Entity as DecribeEntity } from '@lib/entity';
import type { Maybe, User } from '@modules/types/graphql';
import { UserRole } from '@modules/types/graphql';
import { UserEntity } from '@modules/users/entities';

export type Entity = Maybe<Partial<User>>;

@DecribeEntity('CurrentUserEntity')
class CurrentUserEntity extends UserEntity {
    authenticated() {
        const isAuth = this.id !== '';

        return isAuth;
    }

    hasAccess(roles?: UserRole[]) {
        if (!roles || !this.role) {
            return true;
        }

        const hasAccess = roles.includes(this.role);

        return hasAccess;
    }

    isAdmin() {
        const isAdmin = this.role === UserRole.admin;

        return isAdmin;
    }

    isTestable() {
        const isTestable = this.role === UserRole.testable;

        return isTestable;
    }
}

export { CurrentUserEntity };
