import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useNavigate } from '@reach/router';
import { withStyles, InputAdornment } from '@material-ui/core';
import { Person, Lock } from '@material-ui/icons';
import { black } from '@avangard/ui/colors';
import { TextField, Button } from '@avangard/ui/core';

import { TokenSession } from '@lib/token';
import { createRouteUrl } from '@lib/routing';
import { routes } from '@config/routes';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { SignupRequest } from '@modules/auth/requests';
import { SignupMutation } from '@modules/auth/graphql';

import type { SignupMutationType, SignupMutationVariables } from '@modules/types/graphql';

const StyledTextField = withStyles({
    root: {
        marginBottom: 24,
    },
})(TextField);

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
    }

    > h3 {
        color: ${black[80]};
        font-size: 2.8rem;
        font-weight: 500;
    }
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const StyledForm = styled(Form)`
    width: 100%;
`;

const StyledFormRowWithMargin = styled(FormStyled.FormRowWithMargin)`
    &:not(:last-of-type) {
        margin-bottom: 12px;
    }
`;

const personIcon = <Person style={{ fontSize: 16 }} />;
const lockIcon = <Lock style={{ fontSize: 16 }} />;

const SignupForm = (): React.ReactElement => {
    const navigate = useNavigate();

    const [signup] = useMutation<SignupMutationType, SignupMutationVariables>(SignupMutation);

    return (
        <Root>
            <Header>
                <h3>Регистрация</h3>
            </Header>

            <Main>
                <ExtendedFormik
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        email: yup
                            .string()
                            .email('Некорректный формат email')
                            .required('Введите email'),
                        password: yup
                            .string()
                            .required('Обязательное поле')
                            .min(8, 'Пароль должен содержать не менее 8 символов'),
                        confirmPassword: yup
                            .string()
                            .required('Обязательное поле')
                            .oneOf([yup.ref('password'), null], 'Новые пароли должны совпадать'),
                    })}
                    initialValues={{
                        email: '',
                        password: '',
                        confirmPassword: '',
                    }}
                    onSubmit={async values => {
                        try {
                            const variables = new SignupRequest(values);

                            const { data: signupData } = await signup({
                                variables,
                            });

                            if (
                                signupData?.signup?.accessToken &&
                                signupData?.signup?.refreshToken
                            ) {
                                TokenSession.setCurrentSession(signupData?.signup);

                                window.location.href = '/';
                            }
                        } catch (e) {
                            throw e;
                        }
                    }}
                >
                    {formikProps => {
                        const { values, errors, handleChange, handleBlur, isSubmitting } =
                            formikProps;

                        const handleClickRedirectToLogin = (): void => {
                            navigate(createRouteUrl(routes.login), {
                                state: { email: values.email },
                            });
                        };

                        return (
                            <StyledForm>
                                <StyledTextField
                                    fullWidth
                                    id='email'
                                    type='email'
                                    label='E-mail'
                                    placeholder='Например, you@mail.ru'
                                    value={values.email}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                {personIcon}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <StyledTextField
                                    fullWidth
                                    id='password'
                                    type='password'
                                    label='Пароль'
                                    placeholder='Например, 12345678'
                                    value={values.password}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                {lockIcon}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <StyledTextField
                                    fullWidth
                                    id='confirmPassword'
                                    type='password'
                                    label='Пароль ещё раз'
                                    placeholder='Например, 12345678'
                                    value={values.confirmPassword}
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                {lockIcon}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <StyledFormRowWithMargin>
                                    <Button fullWidth type='submit' loading={isSubmitting}>
                                        Зарегистрироваться
                                    </Button>
                                </StyledFormRowWithMargin>

                                <StyledFormRowWithMargin>
                                    <Button
                                        fullWidth
                                        variant='text'
                                        type='button'
                                        disabled={isSubmitting}
                                        onClick={handleClickRedirectToLogin}
                                    >
                                        Авторизоваться
                                    </Button>
                                </StyledFormRowWithMargin>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            </Main>
        </Root>
    );
};

export { SignupForm };
