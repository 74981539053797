import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { DictationQuestionEntity } from '@modules/events/dictation/entities';

import type { ActiveDictationQuestion } from '@modules/types/graphql';

export type Entity = Partial<ActiveDictationQuestion> | null;

@DecribeEntity('ActiveDictationQuestionEntity')
class ActiveDictationQuestionEntity extends BaseEntity {
    questionIndex: number;

    @Relation(() => DictationQuestionEntity)
    question: DictationQuestionEntity;

    constructor(activeDictationQuestion: Entity) {
        super(activeDictationQuestion);

        this.questionIndex = activeDictationQuestion?.questionIndex ?? 0;
    }
}

export { ActiveDictationQuestionEntity };
