import { gql } from '@apollo/client';

import { DictationFragment } from '@modules/events/dictation/graphql';

export const StartDictationByEventMutation = gql`
    mutation StartDictationByEvent($eventId: ID!, $questionsCount: Int!) {
        startDictationByEvent(eventId: $eventId, questionsCount: $questionsCount) {
            ...Dictation
        }
    }
    ${DictationFragment}
`;
