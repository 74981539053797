import React from 'react';

import { ReactComponent as AvangardLogoIcon } from './source/avangard-logo.svg';
import { Root } from './shared/styled';

import type { IconProps } from './shared/types';

const AvangardLogo = (props: IconProps): React.ReactElement => {
    return (
        <Root {...props}>
            <AvangardLogoIcon />
        </Root>
    );
};

export { AvangardLogo };
