import React from 'react';

import { ReactComponent as DictantLogoIcon } from './source/dictant-logo.svg';
import { Root } from './shared/styled';

import type { IconProps } from './shared/types';

const DictantLogo = (props: IconProps): React.ReactElement => {
    return (
        <Root {...props}>
            <DictantLogoIcon />
        </Root>
    );
};

export { DictantLogo };
