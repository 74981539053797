import { gql } from '@apollo/client';

export const GetLocationsByRegionQuery = gql`
    query GetLocationsByRegion($regionId: ID!) {
        getLocationsByRegion(regionId: $regionId) {
            id
            regionId
            name
            region {
                id
                name
            }
        }
    }
`;
