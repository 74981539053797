import { gql } from '@apollo/client';

import { DictationQuestionFragment } from '@modules/events/dictation/graphql';

export const PrevQuestionOfDictationMutation = gql`
    mutation PrevQuestionOfDictation($dictationId: ID!) {
        prevQuestionOfDictation(dictationId: $dictationId) {
            questionIndex
            question {
                ...DictationQuestion
            }
        }
    }
    ${DictationQuestionFragment}
`;
