import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SidebarLogoIcon } from './source/sidebar-logo.svg';

import type { IconProps } from './shared/types';

const Root = styled.div`
    display: inline-flex;
    padding: 19px 24px;

    > svg {
        width: 100%;
        height: auto;
    }
`;

const SidebarLogo = (props: IconProps): React.ReactElement => {
    return (
        <Root {...props}>
            <SidebarLogoIcon />
        </Root>
    );
};

export { SidebarLogo };
