import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useNavigate } from '@reach/router';
import { useApolloClient, useMutation } from '@apollo/client';
import { black } from '@avangard/ui/colors';
import { TextField, Button } from '@avangard/ui/core';

import { routes } from '@config/routes';
import { TokenSession } from '@lib/token';
import { FormStyled } from '@modules/layout/styled';
import { useLocationQuery } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { ResetPasswordRequest } from '@modules/auth/requests';
import { useUserInfoByToken } from '@modules/auth/hooks';
import { ResetPasswordMutation } from '@modules/auth/graphql';

import type {
    ResetPasswordMutationType,
    ResetPasswordMutationVariables,
} from '@modules/types/graphql';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
    }
`;

const Title = styled.h3`
    color: ${black[80]};
    font-size: 2.3rem;
    font-weight: 500;
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const StyledForm = styled(Form)`
    width: 100%;

    > *:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const ResetPasswordForm = (): React.ReactElement | null => {
    const navigate = useNavigate();
    const apolloClient = useApolloClient();
    const [query] = useLocationQuery();

    const token = query?.token as string;

    const {
        userInfo,
        loading: userInfoLoading,
        error: userInfoError,
    } = useUserInfoByToken({
        skip: !token,
        variables: {
            token,
        },
        onCompleted: async () => {
            TokenSession.destroyCurrentSession();
            await apolloClient.clearStore();
        },
    });

    const [resetPassword] =
        useMutation<ResetPasswordMutationType, ResetPasswordMutationVariables>(
            ResetPasswordMutation,
        );

    const handleClickRedirectToAuth = (): void => {
        navigate(routes.index.path);
    };

    if (userInfoLoading) {
        return (
            <Root>
                <Loader />
            </Root>
        );
    }

    if (userInfoError) {
        return (
            <Root>
                <Header>
                    <Title>Ссылка на восстановление пароля больше недействительна</Title>
                    <Main>
                        <Button onClick={handleClickRedirectToAuth}>Вернуться к авторизации</Button>
                    </Main>
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Title>Восстановление пароля для {userInfo.name}</Title>
            </Header>

            <Main>
                <ExtendedFormik
                    validationSchema={yup.object({
                        password: yup
                            .string()
                            .required('Новый пароль обязателен для заполнения')
                            .min(8, 'Пароль должен содержать не менее 8 символов'),
                        confirmNewPassword: yup
                            .string()
                            .required('Обязательное поле')
                            .oneOf([yup.ref('password'), null], 'Новые пароли должны совпадать'),
                    })}
                    initialValues={{
                        password: '',
                        confirmNewPassword: '',
                    }}
                    onSubmit={async values => {
                        try {
                            const variables = new ResetPasswordRequest({ token, ...values });

                            const { data: resetPasswordData } = await resetPassword({
                                variables,
                            });

                            const result = resetPasswordData?.resetPassword;

                            if (!result) {
                                throw new Error('При смене пароля произошла ошибка!');
                            }

                            TokenSession.setCurrentSession(result);

                            window.location.href = '/';
                        } catch (e) {
                            throw e;
                        }
                    }}
                >
                    {formikProps => {
                        const { values, errors, handleChange, handleBlur, isSubmitting } =
                            formikProps;

                        return (
                            <StyledForm>
                                <TextField
                                    fullWidth
                                    type='password'
                                    id='password'
                                    label='Новый пароль'
                                    placeholder='Например, 12345678'
                                    value={values.password}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <TextField
                                    fullWidth
                                    type='password'
                                    id='confirmNewPassword'
                                    label='Новый пароль еще раз'
                                    placeholder='Например, 12345678'
                                    value={values.confirmNewPassword}
                                    error={!!errors.confirmNewPassword}
                                    helperText={errors.confirmNewPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <FormStyled.FormRowSubmit>
                                    <Button fullWidth type='submit' loading={isSubmitting}>
                                        Сохранить и войти
                                    </Button>
                                </FormStyled.FormRowSubmit>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            </Main>
        </Root>
    );
};

export { ResetPasswordForm };
