import { gql } from '@apollo/client';

import { FileFragment } from '@modules/shared/graphql/fragments';

export const DictationAnswerFragment = gql`
    fragment DictationAnswer on DictationAnswer {
        id
        photoId
        text
        photo {
            ...File
        }
        isCorrect
        selected
    }
    ${FileFragment}
`;
