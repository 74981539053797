import React from 'react';

import { TableCell, TableSortLabel } from '@modules/layout/moleculas';

import type { TableCellType } from '@modules/layout/organisms';
import type { SortDirection } from '@modules/types/graphql';

type TableHeadCellProps = {
    column: TableCellType;
    onClick: (event: React.MouseEvent<HTMLSpanElement>) => void;
    active?: boolean;
    direction?: SortDirection;
};

const TableHeadCell = (props: TableHeadCellProps): React.ReactElement => {
    const { column, active, direction, onClick, ...otherProps } = props;

    if (column.sortable === false) {
        return <TableCell {...otherProps}>{column.label}</TableCell>;
    }

    return (
        <TableCell key={column.id} width={column.width} {...otherProps}>
            <TableSortLabel active={active} direction={direction} onClick={onClick}>
                {column.label}
            </TableSortLabel>
        </TableCell>
    );
};

export { TableHeadCell };
