import { gql } from '@apollo/client';

export const UserFragment = gql`
    fragment User on User {
        id
        name
        email
        role
        locationId
        location {
            id
            regionId
            name
            region {
                id
                name
            }
        }
        phone
        schoolName
        greetingPassed
    }
`;
