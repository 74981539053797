import { gql } from '@apollo/client';

import { AuthTokensFragment } from '../fragments';

export const LoginMutation = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            ...AuthTokens
        }
    }
    ${AuthTokensFragment}
`;
