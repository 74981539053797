import loadable, { LoadableComponent } from '@loadable/component';
import { DynamicFeed, Group } from '@material-ui/icons';

import { AuthLayout, BaseLayout, EmptyLayout, GreetingLayout } from '@modules/layout/templates';
import { UserRole } from '@modules/types/graphql';

import type { RedirectProps, RouteComponentProps } from '@reach/router';

export enum RouteMenuLocationEnum {
    sidebar = 'sidebar',
}

export type OriginRoute = {
    name: string;
    title: string;
    path: string;
    private: boolean;
    LayoutComponent: React.FunctionComponent<any>;
    componentPath?: string;
    renderForPath?: Partial<Record<UserRole, string>>;
    redirectTo?: string;
    withBackIcon?: boolean;
    default?: boolean;
    roles?: UserRole[];
    titleFor?: Partial<Record<UserRole, string>>;
    menuTitle?: string;
    menuTitleFor?: Partial<Record<UserRole, string>>;
    menuLocations?: RouteMenuLocationEnum[];
    menuIcon?: React.FunctionComponent<any>;
    children?: React.ReactNode;
};

export type Route = Omit<OriginRoute, 'renderForPath' | 'componentPath'> & {
    renderFor?: Partial<Record<UserRole, LoadableComponent<any>>>;
    Component?: LoadableComponent<any>;
};

export type OriginRoutes = Record<string, OriginRoute>;
export type ConfigRoutes = Record<string, Route>;

export type RedirectRoute = RouteComponentProps<RedirectProps<{}>> & {
    name: string;
};

const originRoutes: OriginRoutes = {
    index: {
        name: 'index',
        title: 'Index',
        path: '/',
        private: true,
        componentPath: '@pages/index',
        LayoutComponent: EmptyLayout,
    },

    register: {
        name: 'auth.register',
        title: 'Регистрация',
        path: '/auth/register',
        private: false,
        componentPath: '@pages/auth/register',
        LayoutComponent: AuthLayout,
    },

    login: {
        name: 'auth.login',
        title: 'Авторизация',
        path: '/auth/login',
        private: false,
        componentPath: '@pages/auth/login',
        LayoutComponent: AuthLayout,
    },

    greeting: {
        name: 'auth.greeting',
        title: 'Анкета',
        path: '/auth/greeting',
        private: true,
        componentPath: '@pages/auth/greeting',
        LayoutComponent: GreetingLayout,
    },

    forgotPassword: {
        name: 'auth.forgotPassword',
        title: 'Восстановление пароля',
        path: '/auth/forgot-password',
        private: false,
        componentPath: '@pages/auth/forgot-password',
        LayoutComponent: AuthLayout,
    },

    resetPassword: {
        name: 'auth.resetPassword',
        title: 'Сброс пароля',
        path: '/auth/reset-password',
        private: false,
        componentPath: '@pages/auth/reset-password',
        LayoutComponent: AuthLayout,
    },

    events: {
        name: 'events.index',
        title: 'Мероприятия',
        menuTitle: 'Мероприятия',
        path: '/events',
        private: true,
        roles: [UserRole.admin, UserRole.testable],
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuIcon: DynamicFeed,
        componentPath: '@pages/events/main/index',
        LayoutComponent: BaseLayout,
    },
    eventsSinglePage: {
        name: 'events.single',
        title: '',
        path: '/events/:eventId',
        private: true,
        withBackIcon: true,
        roles: [UserRole.admin, UserRole.testable],
        componentPath: '@pages/events/main/admin',
        renderForPath: {
            [UserRole.testable]: '@pages/events/main/testable',
        },
        LayoutComponent: BaseLayout,
    },
    dictation: {
        name: 'events.dictation',
        title: '',
        path: '/events/:eventId/dictation',
        private: true,
        withBackIcon: true,
        roles: [UserRole.admin, UserRole.testable],
        componentPath: '@pages/events/dictation/admin',
        renderForPath: {
            [UserRole.testable]: '@pages/events/dictation/testable',
        },
        LayoutComponent: BaseLayout,
    },
    dictationStream: {
        name: 'dictationStream',
        title: 'Диктант',
        path: '/:eventId',
        private: false,
        componentPath: '@pages/events/dictation/stream',
        LayoutComponent: EmptyLayout,
    },

    users: {
        name: 'users.index',
        title: 'Участники',
        path: '/users',
        private: true,
        roles: [UserRole.admin],
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuIcon: Group,
        componentPath: '@pages/users',
        LayoutComponent: BaseLayout,
    },
    usersSinglePage: {
        name: 'users.single',
        title: '',
        path: '/users/:id',
        private: true,
        withBackIcon: true,
        roles: [UserRole.admin],
        componentPath: '@pages/users/single',
        LayoutComponent: BaseLayout,
    },

    profile: {
        name: 'profile.index',
        title: 'Профиль',
        path: '/profile',
        private: true,
        componentPath: '@pages/profile/index',
        LayoutComponent: BaseLayout,
    },

    profileEdit: {
        name: 'profile.edit',
        title: 'Редактирование профиля',
        path: '/profile/edit',
        private: true,
        withBackIcon: true,
        componentPath: '@pages/profile/edit',
        LayoutComponent: BaseLayout,
    },

    error: {
        name: 'error',
        title: 'Ошибка сервиса',
        path: '/error',
        private: false,
        componentPath: '@pages/index',
        LayoutComponent: EmptyLayout,
    },
};

const routes = Object.entries(originRoutes).reduce((carry, [key, route]) => {
    let asyncComponent: Route['Component'] = undefined;
    let renderFor: Route['renderFor'] = undefined;

    if (route.componentPath) {
        const path = route.componentPath.replace('@', '');

        asyncComponent = loadable(() => import(/* webpackPrefetch: true */ `../${path}`));
    }

    if (route.renderForPath) {
        renderFor = Object.entries(route.renderForPath).reduce((carry, [role, componentPath]) => {
            if (componentPath) {
                const path = componentPath.replace('@', '');
                const asyncComponent = loadable(
                    () => import(/* webpackPrefetch: true */ `../${path}`),
                );

                return { ...carry, [role]: asyncComponent };
            }

            return carry;
        }, {});
    }

    return {
        ...carry,
        [key]: { ...route, renderFor, Component: asyncComponent },
    };
}, {} as ConfigRoutes);

const redirects: RedirectRoute[] = [
    {
        name: 'default',
        from: '/',
        to: routes.index.path,
        default: true,
    },
];

export { routes, redirects };
