import { EntityList } from '@lib/entity';
import { DictationEntity } from './dictation-entity';

import type { Entity } from './dictation-entity';

class DictationEntityList extends EntityList<DictationEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(DictationEntity, items, count);
    }
}

export { DictationEntityList };
