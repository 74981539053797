import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Tab } from '@avangard/ui/core';
import { concatenateClasses } from '@avangard/ui/utils';

import { blue } from '@modules/layout/styled';

import type { TabProps } from '@avangard/ui/core';

const useTabStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'transparent',

        [theme.breakpoints.down('md')]: {
            padding: 0,

            '&:not(:last-child)': {
                marginRight: 24,
            },
        },
    },

    textColorInherit: {
        '&$selected': {
            color: blue[100],
            backgroundColor: blue[10],

            [theme.breakpoints.down('md')]: {
                color: blue[100],
                backgroundColor: 'transparent',
            },
        },
    },

    wrapper: {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },

    selected: {},
}));

const TabEdit = (props: TabProps): React.ReactElement => {
    const { classes, ...otherProps } = props;

    const tabClasses = concatenateClasses(useTabStyles(), classes);

    return <Tab classes={tabClasses} {...otherProps} />;
};

export { TabEdit };
