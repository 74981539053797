import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useNavigate } from '@reach/router';
import { useApolloClient, useMutation } from '@apollo/client';
import { white } from '@avangard/ui/colors';
import { Button } from '@avangard/ui/core';
import { breakpoints } from '@avangard/ui/utils';
import { ExitToApp } from '@material-ui/icons';

import { routes } from '@config/routes';
import { TokenSession } from '@lib/token';
import { AvangardLogo, StockLogo } from '@modules/layout/icons';
import { RevokeTokenMutation } from '@modules/auth/graphql/mutations';

import type { RevokeTokenMutationType, RevokeTokenMutationVariables } from '@modules/types/graphql';

type GreetingProps = {
    children: ReactNode;
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 640px;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    ${breakpoints.down('sm')} {
        max-width: 100%;
        border: none;
        box-shadow: none;
        height: 100%;
        padding: 50px 20px 50px;
    }
`;

const Logos = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    & > *:not(:last-child) {
        margin-right: 30px;
    }
`;

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('/images/main-background.jpg');
    background-size: 100% auto;
    text-align: center;
    width: 100%;
    height: 278px;
    color: ${white[100]};

    > h3 {
        font-size: 2.4rem;
        font-weight: 500;
    }

    > p {
        font-size: 1.4rem;
        letter-spacing: 0.15px;
        margin-top: 16px;
    }

    ${breakpoints.down('sm')} {
        background: none;
    }
`;

const Main = styled.div`
    display: flex;
    width: 100%;
    background: ${white[100]};
    padding: 24px;
`;

const LogoutButton = styled(Button)`
    position: absolute;
    z-index: 3;
    right: 2%;
    top: 5%;
    color: ${white[100]};
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${breakpoints.down('sm')} {
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -30px;
    }
`;

const logoutIcon = <ExitToApp fontSize='large' />;

const Greeting = (props: GreetingProps): React.ReactElement => {
    const { children } = props;

    const navigate = useNavigate();
    const apolloClient = useApolloClient();

    const [logout, { loading: logoutLoading }] =
        useMutation<RevokeTokenMutationType, RevokeTokenMutationVariables>(RevokeTokenMutation);

    const handleClickLogout = async (): Promise<void> => {
        const refreshToken = TokenSession.getCurrentSession().getRefreshToken().getToken();

        if (!refreshToken) {
            return;
        }

        try {
            await logout({
                variables: { refreshToken },
            });
        } catch (e) {
            console.log(e);
        } finally {
            TokenSession.destroyCurrentSession();
            await apolloClient.clearStore();
            await navigate(routes.login.path, { replace: true });
        }
    };

    return (
        <Root>
            <Header>
                <Logos>
                    <StockLogo />
                    <AvangardLogo />
                </Logos>
                <h3>Привет!</h3>
                <p>Осталось несколько шагов до начала, пожалуйста, заполни поля ниже</p>

                <LogoutButton
                    variant='text'
                    endIcon={logoutIcon}
                    disabled={logoutLoading}
                    onClick={handleClickLogout}
                >
                    Выйти
                </LogoutButton>
            </Header>

            <Main>{children}</Main>
        </Root>
    );
};

export { Greeting };
